import React from "react";
import {
  CallToAction,
  ContactInfo,
  ContentService,
  Layout,
  Newsletter
} from "../components";
import { useService } from "../redux/hooks/hooks";
//import { service1Image } from "../assets";

const Service = () => {
  const data = useService();
  return (
    <Layout data={data}>
      <div
        className="md:h-80 bg-gray-100 h-60 flex  bg-no-repeat bg-cover bg-center w-full"
        style={{ backgroundImage: `url(${data?.hero?.image})` }}
      >
        <div className="container pt-24">
          <div
            className="font-[700] text-[2rem] text-white"
            style={{ textShadow: "1px 1px 1px black" }}
          >
            {/* <FormattedMessage id="our.services" /> */}
            {data?.hero?.title}
          </div>
          <div
            className="text-[1rem] text-white"
            style={{ textShadow: "1px 1px 1px black" }}
          >
            {/* <FormattedMessage id="our.services" /> */}
            {data?.hero?.description}
          </div>
        </div>
      </div>
      <div className="container pt-4 pb-24">
        <div className="flex flex-col md:flex-row w-full md:gap-5">
          <ContentService />
          <div className="w-full md:w-1/3">
            <ContactInfo />
            <div className="flex justify-center pt-8">
              <CallToAction />
            </div>
            <div className="pt-8 md:px-2">
              <Newsletter />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Service;
