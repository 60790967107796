import React from "react";
import { FormattedMessage } from "react-intl";
import { useContactus } from "../../redux/hooks/hooks";

const ContactInfo = () => {
    const data = useContactus();

    return (
        <div className="w-full flex flex-col gap-y-9 md:px-2 pt-8">
            <div className="bg-gray-200 p-5">
                <span className="font-[700] text-[1.2rem] text-center">{data?.name}</span>
                <hr className="w-10 h-1 bg-primary" />
                <div className="pt-4">
                    <div className="pb-2">
                        <span className="font-[600]">Communication</span>
                    </div>
                    <div className="flex gap-2">
                        <span>Tel:</span>
                        <span>{data?.phone}</span>
                    </div>
                    <div className="flex gap-2">
                        <span>Fax:</span>
                        <span>{data?.fax}</span>
                    </div>
                    <div className="flex gap-2">
                        <span>Email:</span>
                        <p className="break-all">{data?.email}</p>
                    </div>
                </div>
            </div>
            <div className="bg-gray-200 p-5">
                <span className="font-[700] text-[1rem]">
                    <FormattedMessage id="office.address" />
                </span>
                <div className="pt-2">
                    <div className="flex flex-col gap-2">
                        <span>{data?.address}</span>
                        <span>{data?.postBox}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
