import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { useLanguage } from "../../context";
import { getDatePost2 } from "../../helpers/utils";
import { setLocalStorage, slugBlog, slugCategory } from "../../helpers/localStorage";

const BlogV = ({ data }) => {
  const { language } = useLanguage();
  const intl = useIntl();

  const onPress = () => {
    setLocalStorage(slugCategory, data?.category?.slug?.en?.current);
    setLocalStorage(slugBlog, data?.slug?.en?.current);
  };

  console.log('data?._updatedAt -- ',data?._updatedAt)
  return (
    <div className="w-full">
      <div className="flex flex-col w-full">
        {/* <img
          src={urlFor(data.image).fit("max").url()}
          className="h-[120px] w-full object-cover"
          alt=""
        /> */}
        <NavLink
          to={`${intl.formatMessage({
            id: "link.blog",
          })}/${data?.slug?.en?.current}`}
          className="h-[120px] w-full flex bg-center bg-cover"
          style={{
            backgroundImage: `url(${urlFor(data.image).fit("max").url()})`,
          }}
          onClick={() => onPress()}
        />
        <div className="pt-3">
          <NavLink
            to={`${intl.formatMessage({
              id: "link.blog.categories",
            })}/${data.category?.slug?.en?.current}`}
            className="text-light hover:text-light/70"
          >
            {data.category?.title}
          </NavLink>
          <div className="pt-1">
            <NavLink
              to={`${intl.formatMessage({
                id: "link.blog",
              })}/${data?.slug?.en?.current}`}
              className="font-[600] text-[0.85rem] hover:text-black/70"
              onClick={() => onPress()}
            >
              {data.title}
            </NavLink>
          </div>
          <div className="flex items-center justify-end text-italic">
            <span className="date text-light-666">
              {getDatePost2(data?._updatedAt, language)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogV;
