import { createClient } from "@sanity/client";

const options = {
    dataset: "production", //
    projectId: "7uiaikl7", //
    useCdn: true,
    apiVersion: "2023-05-03", //2021-10-21 2023-05-03
};

export const client = createClient(options);
