import { UPDATE_SERVICE } from "../constants";

const initialState = null;

export const setServiceData = (data) => ({
    type: UPDATE_SERVICE,
    data,
});

export const service = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SERVICE:
            return action.data;
        default:
            return state;
    }
};
