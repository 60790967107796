import React from "react";
import { NavLink } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { logo } from "../../assets";
import { useContactus } from "../../redux/hooks/hooks";
import MegaMenu from "./jsx/MegaMenu";
import MegaMenuMobile from "./MegaMenuMobile";

const Nav = () => {
  const contact = useContactus();

  return (
    <div className="container bg-white lg:py-5 py-4">
      <nav
        className="flex w-full justify-between items-center flex-wrap md:flex-nowrap"
        data-twe-navbar-ref
      >
        <div className="flex items-center">
          <NavLink to="/">
            <img
              className="block w-[7rem] md:w-[8rem]  md:h-[7rem]"
              src={
                contact?.logo ? urlFor(contact?.logo).fit("max").url() : logo
              }
              alt="AFS"
            />
          </NavLink>
        </div>
        <div className="hidden md:flex w-full">
          <MegaMenu />
        </div>
        <div className="flex w-full md:hidden">
          <MegaMenuMobile />
        </div>
      </nav>
    </div>
  );
};

export default Nav;
