import { facebook, linkedin, youtube } from "../assets";
export const usefulLinks = [
    // { name: "Home", href: "link.home", id: "home" },
    // {
    //     name: "Services",
    //     href: "link.services",
    //     id: "services",
    // },
    // {
    //     name: "Products",
    //     href: "link.products",
    //     id: "products",
    // },
    {
        name: "Contact",
        href: "link.contact",
        id: "contact",
    },
    { name: "About", href: "link.about", id: "about" },
    { name: "News", href: "link.news", id: "news" },
];

export const contactUsLinks = [
    { name: "facebook", url: "link.about", image: facebook },
    { name: "linkedin", url: "link.about", image: linkedin },
    { name: "youtube", url: "link.about", image: youtube },
];
