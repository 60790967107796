import React, { useEffect } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Ripple, initTWE } from "tw-elements";
import { useLanguage } from "../../context";
import { languageStorage, setLocalStorage } from "../../helpers/localStorage";
import { getUrlOpposite, getUrlOppositeBlog } from "../../helpers/utils";

const Language = () => {
  const intl = useIntl();
  const { language, updateLanguage } = useLanguage();
  const { pathname, state } = useLocation();
  // const params = useParams();
  let history = useNavigate();
  let urlOpposite = "";

  useEffect(() => {
    initTWE({ Dropdown, Ripple });
  }, []);

  const langs = [
    {
      key: "en",
      value: intl.formatMessage({ id: "en" }),
    },
    {
      key: "fr",
      value: intl.formatMessage({ id: "fr" }),
    },
    // {
    //   key: "es",
    //   value: intl.formatMessage({ id: "es" }),
    // },
  ];

  const changeLanguage = (value) => {
    if (language !== value) {
      console.log("value ---- changeLanguage ", value);
      setLocalStorage(languageStorage, value);
      updateLanguage();
      // console.log("location pathname --- ", location.pathname);
      if (
        `${pathname}`.startsWith("/blog") ||
        `${pathname}`.startsWith("/fr/blog") ||
        `${pathname}`.startsWith("/blog/categories") ||
        `${pathname}`.startsWith("/fr/blog/categories") ||
        `${pathname}`.startsWith("/info") ||
        `${pathname}`.startsWith("/fr/info") ||
        `${pathname}`.startsWith("/fr/sub") ||
        `${pathname}`.startsWith("/sub") ||
        `${pathname}`.startsWith("/fr/slider") ||
        `${pathname}`.startsWith("/slider") ||
        `${pathname}`.startsWith("/fr/service") ||
        `${pathname}`.startsWith("/service")
      ) {
        urlOpposite = getUrlOppositeBlog(pathname);
      } else {
        urlOpposite = getUrlOpposite(pathname);
      }
      // urlOpposite = getUrlOpposite(pathname);
      history(urlOpposite, {
        replace: true,
        state: state,
      });
    }
  };

  return (
    <div className="flex items-center gap-3">
      {/* border-l-[1px] border-l-white */}
      {/* <select
        className="text-[0.8rem] appearance-none border-none text-white bg-black-150 cursor-pointer focus:outline-0  focus:ring-0"
        value={language}
        onChange={(e) => changeLanguage(e.target.value)}
      >
        <option value="en" className="text-black bg-white cursor-pointer">
          {intl.formatMessage({ id: "en" })}
        </option>
        <option value="fr" className="text-black bg-white cursor-pointer">
          {intl.formatMessage({ id: "fr" })}
        </option>
        <option value="fr" className="text-black bg-white cursor-pointer">
          {intl.formatMessage({ id: "es" })}
        </option>
      </select> */}

      {/* <Dr2
        as={"div"}
        label=""
        value={language}
        onChange={(e) => changeLanguage(e.target.value)}
        className="!py-0 rounded-none bg-black-150 !-top-[4px] border-t-0"
        renderTrigger={() => (
          <div className="flex items-center gap-1 cursor-pointer">
            <span className="py-1 text-white text-[0.8rem]">
              {intl.formatMessage({ id: language })}
            </span>
            <HiChevronDown className="text-white" />
          </div>
        )}
        dismissOnClick={false}
      >
        {langs.map((lang, i) => (
          <DropdownItem
            key={lang.key}
            as={"div"}
            onClick={() => changeLanguage(lang.key)}
            className={`text-white text-[0.8rem] focus:outline-0 focus:outline-offset-0 !py-1 hover:!bg-gray-100 hover:!text-black ${language === lang.key ? "!bg-gray-100 text-black" : ""
              }`}
          >
            {intl.formatMessage({ id: lang.key })}
          </DropdownItem>
        ))}
      </Dr2> */}

      <div className="relative px-3" data-twe-dropdown-ref>
        <button
          className="flex items-center gap-1 text-white text-[0.8rem] transition duration-150 ease-in-out focus:outline-0 focus:ring-0 motion-reduce:transition-none"
          type="button"
          id="dropdownLanguage"
          data-twe-dropdown-toggle-ref
          aria-expanded="false"
          data-twe-ripple-init
          data-twe-ripple-color="light"
        >
          {intl.formatMessage({ id: language })}
          <HiChevronDown className="text-white" />
        </button>
        <ul
          className="absolute z-[1000] float-left m-0 !top-[1.7rem] !transform-none hidden min-w-full list-none overflow-hidden rounded-none border-none bg-gray-100  bg-clip-padding text-[0.8em] shadow-lg data-[twe-dropdown-show]:block"
          aria-labelledby="dropdownLanguage"
          data-twe-dropdown-menu-ref
        >
          {langs.map((lang, i) => (
            <li
              data-twe-dropdown-item-ref
              key={lang.key}
              onClick={() => changeLanguage(lang.key)}
              className={`px-4 py-1 text-[0.8rem] cursor-pointer focus:outline-0 focus:outline-offset-0  hover:!bg-gray-100 hover:!text-black ${language === lang.key
                  ? "!bg-black-150 !text-white"
                  : "text-black"
                }`}
            >
              {intl.formatMessage({ id: lang.key })}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Language;
