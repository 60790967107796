import React, { useEffect } from "react";
import { RiMenu3Fill } from "react-icons/ri";
import { useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Dropdown, Ripple, initTWE } from "tw-elements";
import { useMenu } from "../../redux/hooks/hooks";

const MegaMenuMobile = () => {
    const location = useLocation();
    // const contact = useContactus();
    const menus = useMenu();
    const intl = useIntl();

    useEffect(() => {
        initTWE({ Collapse, Dropdown, Ripple });
    }, []);

    return (
        <div className="relative flex w-full flex-col">
            <div className="inline-flex w-fit  absolute right-0 top-[-60px] mr-2">
                <button
                    className="border-0 bg-transparent  text-black hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-0 focus:ring-0"
                    type="button"
                    data-twe-collapse-init
                    data-twe-target="#navbarContent"
                    aria-controls="navbarContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <RiMenu3Fill className="text-black w-7 h-7" />
                </button>
            </div>

            <div
                className="!visible hidden w-full items-center justify-end"
                id="navbarContent"
                data-twe-collapse-item
            >
                <ul
                    className="flex flex-col py-4 gap-5 border-t shadow px-3"
                    data-twe-navbar-nav-ref
                >
                    {menus?.map((menu, index) => (
                        <li
                            key={index}
                            data-twe-nav-item-ref
                            data-twe-dropdown-ref
                            className="group flex flex-col gap-1"
                        >
                            <NavLink
                                id={`${menu?._id}-${menu?.title}`}
                                data-twe-dropdown-toggle-ref
                                aria-expanded="false"
                                data-twe-nav-link-ref
                                key={index}
                                to={`/`}
                                className={`text-light font-medium transition duration-200 cursor-pointer menu-item-inactive py-1 before:bg-light hover:ease-in-out ${location.pathname === menu?._id
                                        ? "focus:before:w-full  active:before:w-full before:w-full"
                                        : ""
                                    }`}
                            >
                                {menu?.title}
                            </NavLink>

                            <div
                                aria-labelledby={`${menu?._id}-${menu?.title}`}
                                data-twe-dropdown-menu-ref
                                className="relative transition hidden duration-300 ease-in-out transform min-w-ful0 data-[twe-dropdown-show]:block"
                            >
                                <div className="relative px-6 pt-2 bg-white w-full">
                                    <div className="relative">
                                        <div className="p-4">
                                            <div className="grid gap-3 md:grid-cols-2">
                                                {menu?.submenus?.map((submenu, index) => (
                                                    <NavLink
                                                        key={index}
                                                        to={`${intl.formatMessage({
                                                            id: "link.info",
                                                        })}/${menu?.slug?.en?.current}/${submenu?.slug?.en?.current
                                                            }`}
                                                        className="w-ful pb-4 cursor-pointer flex flex-col"
                                                    >
                                                        <span className="font-medium text-light pb-1">
                                                            {submenu?.title}
                                                        </span>
                                                        <hr className="w-10 h-1 bg-light" />
                                                        <p className="text-wrap text-[0.8rem] mt-2 text-black/80 py-2">
                                                            {submenu?.description}
                                                        </p>
                                                    </NavLink>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MegaMenuMobile;
