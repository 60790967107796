import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  getLocalStorage,
  slugBlog,
  slugCategory,
} from "../../helpers/localStorage";
import { filterBlogsBySearch, getRelatedBlog } from "../../helpers/utils";
import { useNews } from "../../redux/hooks/hooks";
import Newsletter from "../newsletter/Newsletter";
import BlogRelated from "./BlogRelated";

const RelatedBlog = () => {
  const intl = useIntl();
  const data = useNews();
  const [blgs, setBlgs] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const slugCateg = getLocalStorage(slugCategory);
  const slugBlg = getLocalStorage(slugBlog);

  useEffect(() => {
    const relatedBlogs = getRelatedBlog(data?.blogs, slugCateg, slugBlg);
    setBlgs(relatedBlogs);
    setBlogs(relatedBlogs?.slice(0, 2));
  }, [data, slugCateg, slugBlg]);

  const onSearch = (text) => {
    if (text) {
      setBlogs(filterBlogsBySearch(blgs, text));
    } else {
      setBlogs(blgs?.slice(0, 2));
    }
  };

  return (
    <div className="md:sticky top-10">
      <input
        type="search"
        onChange={(e) => onSearch(e.target.value)}
        className="w-full rounded-full text-sm italic h-12 outline-none px-8 py-4 border focus:ring-light"
        style={{ border: "1px solid rgba(220,220,220)" }}
        placeholder={intl.formatMessage({ id: "search" })}
      />
      <div className="mt-12 mb-8 text-gray-s font-poppins">
        {intl.formatMessage({ id: "related.posts" })}
      </div>
      <div className="grid grid-cols-1 gap-4 w-100">
        {blogs &&
          blogs.map((blog, index) => <BlogRelated data={blog} key={index} />)}
      </div>
      <div className="pt-8">
        <Newsletter />
      </div>
    </div>
  );
};

export default RelatedBlog;
