import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { ErrorPage, UnknowErrorPage } from "../components";
import {
    About,
    Blog,
    BlogCategory,
    BlogDetail,
    Contact,
    Home,
    Product,
    Service,
    ServiceDetail,
    SliderPage,
} from "../pages";
import SubMenuPage from "../pages/SubMenuPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        // errorElement: <ErrorPage />,
        ErrorBoundary: () => <UnknowErrorPage />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "services",
                element: <Service />,
            },
            {
                path: "fr/services",
                element: <Service />,
            },
            {
                path: "products",
                element: <Product />,
            },
            {
                path: "produits",
                element: <Product />,
            },
            {
                path: "about",
                element: <About />,
            },
            {
                path: "a-propos",
                element: <About />,
            },
            {
                path: "contact",
                element: <Contact />,
            },
            {
                path: "fr/contact",
                element: <Contact />,
            },
            {
                path: "news",
                element: <Blog />,
            },
            {
                path: "actualites",
                element: <Blog />,
            },
            {
                path: "blog/categories/:slug",
                element: <BlogCategory />,
            },
            {
                path: "fr/blog/categories/:slug",
                element: <BlogCategory />,
            },
            {
                path: "blog/:slug",
                element: <BlogDetail />,
            },
            {
                path: "fr/blog/:slug",
                element: <BlogDetail />,
            },
            {
                path: "service/:slug",
                element: <ServiceDetail />,
            },
            {
                path: "fr/service/:slug",
                element: <ServiceDetail />,
            },
            {
                path: "info/:slug/:slug",
                element: <SubMenuPage />,
            },
            {
                path: "fr/info/:slug/:slug",
                element: <SubMenuPage />,
            },

            {
                path: "sub/:slug/:slug/:slug",
                element: <SubMenuPage />,
            },
            {
                path: "fr/sub/:slug/:slug/:slug",
                element: <SubMenuPage />,
            },
            {
                path: "slider/:slug",
                element: <SliderPage />,
            },
            {
                path: "fr/slider/:slug",
                element: <SliderPage />,
            },
            {
                path: "*",
                element: <ErrorPage />,
            },
        ],
    },
]);

export default router;
