import React from "react";
import { ContactInfo, FormContactUs, Layout, Map } from "../components";
import { useContactus } from "../redux/hooks/hooks";

const Contact = () => {
  const data = useContactus();

  return (
    <Layout data={data}>
      {data && (
        <>
          <div
            className="md:h-80 bg-gray-100 h-60 flex  bg-no-repeat bg-cover bg-center w-full"
            style={{ backgroundImage: `url(${data?.hero?.image})` }}
          >
            <div className="container pt-24">
              <div
                className="font-[700] text-[2rem] text-white"
                style={{ textShadow: "1px 1px 1px black" }}
              >
                {/* <FormattedMessage id="contact" /> */}
                {data?.hero?.title}
              </div>
              {data?.hero?.description && (
                <div
                  className="text-[1rem] text-white"
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  {/* <FormattedMessage id="contact" /> */}
                  {data?.hero?.description}
                </div>
              )}
            </div>
          </div>
          <div className="container pt-6 pb-24">
            {/* <span className="font-[900] text-[2rem]">
          <FormattedMessage id="contact" />
        </span> */}
            <div className="flex flex-col md:flex-row w-full md:gap-5">
              <div className="w-full md:w-3/5">
                <FormContactUs />
              </div>
              <div className="w-full md:w-2/5">
                <ContactInfo />
                <div className="pt-8">
                  <Map
                    adrress={data?.address}
                    lat={data?.location?.lat}
                    lng={data?.location?.lng}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Contact;
