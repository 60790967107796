import { getImageDimensions } from "@sanity/asset-utils";
import React, { useEffect, useState } from "react";
import Viewer from "react-viewer";
import { onChangeInputFields } from "../../helpers/utils";
import { urlFor } from "../../api/sanity/utils";

const GridImage = ({ data }) => {
  const [images, setImages] = useState([]);
  const [viewer, setViewer] = useState({
    show: false,
    image: "",
    activeIndex: 0,
  });

  useEffect(() => {
    if (data) {
      buildImages(data);
    }
  }, [data]);

  const buildImages = (data) => {
    const imgs = data?.map((item) => {
      let url = urlFor(item.image).fit("max").url();
      return { src: url };
    });
    setImages(imgs);
  };

  const ImageViewer = ({ item, index }) => {
    const { width, height } = getImageDimensions(item.image);
    const url = urlFor(item.image).fit("max").url();
    const onClickImage = () => {
      onChangeInputFields(setViewer, "show", true);
      onChangeInputFields(setViewer, "image", url);
      onChangeInputFields(setViewer, "activeIndex", index);
    };
    return (
      <div
        className="cursor-pointer flex items-center justify-center"
        key={index}
        onClick={() => onClickImage()}
      >
        <img
          src={url}
          loading="lazy"
          alt=""
          style={{
            //width: item.width,
            //height: "auto",
            aspectRatio: width / height,
          }}
        />
      </div>
    );
  };

  return (
    <>
      {data && data?.length > 0 && (
        <>
          <div className="gap-6 columns-3 space-y-6">
            {data.map((image, index) => (
              <ImageViewer key={index} item={image} index={index} />
            ))}
          </div>
          <Viewer
            activeIndex={viewer.activeIndex}
            visible={viewer?.show}
            onClose={() => onChangeInputFields(setViewer, "show", false)}
            images={images}
            className="!bg-light opacity-0"
          />
        </>
      )}
    </>
  );
};

export default GridImage;
