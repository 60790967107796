import React from "react";
import { useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { headerLink } from "../../constants/pages";
import Language from "../language/Language";

export default function Header() {
  const location = useLocation();
  const intl = useIntl();

  return (
    <header className="bg-black-150 py-2">
      <div className="container">
        <div className="flex gap-1 justify-end items-center">
          {headerLink.map((page) => (
            <NavLink
              key={page.name}
              to={`${intl.formatMessage({ id: page.href })}`}
              className={`text-[0.8em] px-3 ${
                location.pathname === intl.formatMessage({ id: page.href })
                  ? "text-light font-[700]"
                  : "text-white"
              } ${page.divider ? "border-r-[1px] border-r-white" : ""}`}
            >
              {`${intl.formatMessage({ id: page.id })}`.toUpperCase()}
            </NavLink>
          ))}
          <div className="m-0">
            <Language />
          </div>
        </div>
      </div>
    </header>
  );
}
