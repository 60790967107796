import React from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import CarrouselItem from "./CarrouselItem";
import "./styles.css";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { useNews } from "../../redux/hooks/hooks";

const CarrouselBlog = () => {
  const data = useNews();
  return (
    <Swiper
      effect={"slide"}
      // direction={"vertical"}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      centeredSlides={true}
      autoplay={{
        delay: 6500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay,  Navigation, Pagination]} //EffectFade,
      className="w-full md:h-80 h-[250px] swiper-carroussel-blog"
    >
      {data?.carrouselBlogs?.map((item, index) => (
        <SwiperSlide key={index}>
          <CarrouselItem data={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CarrouselBlog;
