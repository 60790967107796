import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { getMenuBySlug, sortByUpdatedAt } from "../../helpers/utils";
import { useHome } from "../../redux/hooks/hooks";

const FirstBlockHome = () => {
  const data = useHome();
  const [menu, setMenu] = useState();
  const intl = useIntl();

  useEffect(() => {
    if (data?.menus) {
      setMenu(getMenuBySlug(data?.menus, "company"));
    }
  }, [data]);

  console.log("demo -- ", menu?.submenus);
  return (
    <div
      className="container pt-8 pb-4"
    // data-aos="slide-right"
    // data-aos-duration="1000"
    >
      <div className="flex flex-col md:flex-row gap-4">
        {/* {data?.firstBlock?.map((info, index) => ( */}
        {menu &&
          menu?.submenus &&
          sortByUpdatedAt(menu?.submenus)
            ?.slice(0, 3)
            ?.map((info, index) => (
              <div
                key={index}
                className="w-full md:w-1/3 shadow-1 shadow-light p-4 flex flex-col"
              >
                {/* bg-gray-100 */}
                {/* <NavLink
                  className="flex flex-col"
                  to={`${intl.formatMessage({
                    id: "link.info",
                  })}/${menu?.slug?.en?.current}/${info?.slug?.en?.current}`}
                > */}
                <span className="font-[500] text-light">{info.title}</span>
                <hr className="w-10 h-1 bg-light" />
                <p className="text-wrap text-[0.85rem] mt-2 text-black">
                  {info.description}
                </p>
                <NavLink
                  to={`${intl.formatMessage({
                    id: "link.info",
                  })}/${menu?.slug?.en?.current}/${info?.slug?.en?.current}`}
                  className="text-[0.85rem] text-light  mt-1"
                >
                  {intl.formatMessage({
                    id: "read.more",
                  })}
                </NavLink>
                {/* </NavLink> */}
              </div>
            ))}
      </div>
    </div>
  );
};

export default FirstBlockHome;
