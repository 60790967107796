import Cookies from "js-cookie";

export const languageStorage = "@language";
export const slugCategory = "@category";
export const slugBlog = "@slugBlog";

export const setCookie = (key, value) => {
    Cookies.set(key, value, { expires: 1 });
};

export const getCookie = (key) => {
    return Cookies.get(key);
};

export const deleteCookie = (key) => {
    Cookies.remove(key);
};

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
    try {
        var store = localStorage.getItem(key);

        return store ? JSON.parse(store) : "";
    } catch (e) {
        console.log("Try catch error getLocalStorage", e);
        return null;
    }
};

export const deleteLocalStorage = (key) => {
    localStorage.removeItem(key);
};

export const setAuthentication = (result) => {
    setCookie("token", result.data.token);
    setCookie("userId", result.data.uid);
    setCookie("name", result.data.name);
    setCookie("email", result.data.email);
    setCookie("phone", result.data.phone);
    setCookie("image", result.data.image);
    setCookie("myPage", result.data.my_page);
};

export const disconnect = () => {
    Cookies.remove("token");
    Cookies.remove("userId");
    Cookies.remove("name");
    Cookies.remove("email");
    Cookies.remove("phone");
    Cookies.remove("image");
    Cookies.remove("myPage");
};
