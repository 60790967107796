export const UPDATE_HOME = "UPDATE_HOME";
export const UPDATE_ABOUT = "UPDATE_ABOUT";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_CONTACTUS = "UPDATE_CONTACTUS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_FOOTER = "UPDATE_FOOTER";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_MENU = "UPDATE_MENU";
export const UPDATE_SUBMENU_PAGE = "UPDATE_SUBMENU_PAGE";
export const UPDATE_NEWS_DEFAULT = "UPDATE_NEWS_DEFAULT";
export const UPDATE_NEWSLETTER = "UPDATE_NEWSLETTER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
