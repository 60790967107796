import { combineReducers } from "redux";
import { about } from "./about";
import { contactus } from "./contactus";
import { footer } from "./footer";
import { home } from "./home";
import { news } from "./news";
import { product } from "./product";
import { service } from "./service";
import { menu } from "./menu";
import { subMenuPage } from "./subMenuPage";
import { newsDefault } from "./newsDefault";
import { newletter } from "./newletter";
import { sliderPage } from "./sliderPage";

const rootReducer = combineReducers({
    home,
    about,
    footer,
    service,
    news,
    contactus,
    product,
    menu,
    subMenuPage,
    newsDefault,
    newletter,
    sliderPage
});

export default rootReducer;
