import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import {
  filterSubmenusInMenus,
  findMenusWithSubmenu
} from "../../helpers/utils";
import { useHome } from "../../redux/hooks/hooks";

const Slider = () => {
  const data = useHome();
  const intl = useIntl();
  const [sliderData, setSliderData] = useState([]);
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  // console.log("sliderData --- ", sliderData);

  useEffect(() => {
    if (data?.sliders && data?.menus) {
      setSliderData(filterSubmenusInMenus(data?.menus, data?.sliders));
    }
  }, [data]);

  const getMenu = (slug) => {
    let menus = findMenusWithSubmenu(data?.menus, slug);
    return menus?.[0]?.slug?.en.current;
  };
  return (
    <div className="">
      <Swiper
        // effect={"creative"}
        //   direction={"vertical"}
        navigation={false}
        pagination={pagination}
        centeredSlides={true}
        mousewheel={false}
        simulateTouch={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        className="w-full h-60 md:h-80 slider-home"
      >
        {sliderData.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className="bg-no-repeat h-full bg-cover bg-center w-full"
              style={{ backgroundImage: `url(${item.banner}` }}
            >
              <div className="container h-full py-5 flex flex-col gap-2 justify-center pl-8 bg-black/15">
                <div
                  className="text-[1.2rem] text-white px-4  rounded-md text-wrap text-justify"
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  {item.title}
                </div>
                <p
                  className="md:w-[28rem] text-white px-4  rounded-md text-wrap text-justify"
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  {item.description}
                </p>
                <div className="px-4 pt-2">
                  <NavLink
                    // to={`${intl.formatMessage({
                    //   id: "link.slider",
                    // })}/${item?.slug?.current}`}

                    to={`${intl.formatMessage({
                      id: "link.info",
                    })}/${getMenu(item?.slug?.en?.current)}/${item?.slug?.en?.current
                      }`}
                    className="text-[0.85rem] text-white px-3 py-1 bg-light rounded-2xl hover:bg-light/80"
                  >
                    {intl.formatMessage({
                      id: "read.more",
                    })}
                  </NavLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
