import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { getContent, getContentBySlug } from "./api/sanity/utils";
import {
  CustomScrollTop,
  Footer,
  Header,
  Nav,
  ScrollToTop,
} from "./components";
import { useLanguage } from "./context";
import { languageStorage, setLocalStorage } from "./helpers/localStorage";
import {
  getCurrentLanguage,
  getTitle,
  queryAndSet,
  useDocumentTitle,
} from "./helpers/utils";
import { setFooterData } from "./redux/reducers/footer";
import { setContactusData } from "./redux/reducers/contactus";
import { setMenuData } from "./redux/reducers/menu";
import { setNewsDefaultData } from "./redux/reducers/newsDefault";
import { setNewletterData } from "./redux/reducers/newletter";

function App() {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { language, updateLanguage } = useLanguage();
  const dispatch = useDispatch();

  const getData = useCallback(async (language, pathname) => {
    const qs = await queryAndSet(pathname);
    if (qs) {
      const data = await getContent(
        language,
        qs.query,
        qs?.slug,
        qs?.slugMenu,
        qs?.sulgSubMmenu
      );
      if (data && qs?.setData) {
        dispatch(qs.setData(data));
        dispatch(setFooterData(data?.footer));
        if (data?.contact) dispatch(setContactusData(data?.contact));
        if (data?.menus) dispatch(setMenuData(data?.menus));
        if (data?.newsDefault) dispatch(setNewsDefaultData(data?.newsDefault));
        if (data?.newletter) dispatch(setNewletterData(data?.newletter));
      }
    }
  }, []);

  useEffect(() => {
    const currentLanguage = getCurrentLanguage(pathname, language);
    if (currentLanguage != language) {
      setLocalStorage(languageStorage, currentLanguage);
      updateLanguage();
    }
    getData(currentLanguage, pathname);
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/") {
      getData(language, pathname);
    }
  }, [language, pathname]);

  useDocumentTitle(`Africa Systems | ${getTitle(intl, pathname)}`);

  return (
    <main className="flex flex-col h-full">
      <ScrollToTop />
      <Header />
      <Nav />
      <div>
        <Outlet />
      </div>
      <Footer />
      <CustomScrollTop />
    </main>
  );
}

export default App;
