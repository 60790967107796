import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { FaAngleUp } from "react-icons/fa6";

const CustomScrollTop = () => {
  return (
    <ScrollToTop
      smooth
      viewBox="0 0 24 24"
      component={<FaAngleUp className="w-5 h-5 text-white" />}
      className="flex items-center justify-center !bg-black/80 !rounded-none"
    />
  );
};

export default CustomScrollTop;
