import React from "react";
import { Loader } from "..";

const Layout = ({ children, data }) => {
  if (data && data !== undefined) {
    return children;
  }
  return <Loader />;
};

export default Layout;
