import { UPDATE_NEWSLETTER } from "../constants";

const initialState = null;

export const setNewletterData = (data) => ({
    type: UPDATE_NEWSLETTER,
    data,
});

export const newletter = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_NEWSLETTER:
            return action.data;
        default:
            return state;
    }
};
