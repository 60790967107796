import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BlogH,
  ErrorPage,
  Layout,
  PaginatedItems,
  TagBlog,
  TrendingBlogs,
} from "../components";
import { getBlogsByCategory, getCategoryBySlug } from "../helpers/utils";
import { useNews } from "../redux/hooks/hooks";
import { contactImage } from "../assets";
import { FormattedMessage } from "react-intl";

const BlogCategory = () => {
  const data = useNews();
  const params = useParams();
  const category = getCategoryBySlug(data?.categoriesBlogs || [], params?.slug);
  const [categoriesBlogs, setCategoriesBlogs] = useState([]);

  useEffect(() => {
    if (data?.blogs && params?.slug) {
      const b = getBlogsByCategory(data?.blogs, params?.slug);
      console.log("b ---- ", b);
      setCategoriesBlogs(b);
    }
  }, [data, params]);

  // console.log('category ----------- ',category);

  return (
    <Layout data={data}>
      {data?.categoriesBlogs && category ? (
        <>
          <div
            className="md:h-80 bg-gray-100 h-60 flex  bg-no-repeat bg-cover bg-center w-full"
            style={{
              backgroundImage: `url(${category?.image ?? contactImage})`,
            }}
          >
            <div className="container pt-24">
              <div
                className="font-[700] text-[2rem] text-white"
                style={{ textShadow: "1px 1px 1px black" }}
              >
                {category?.title}
              </div>
              <div
                className="text-[1rem] text-white"
                style={{ textShadow: "1px 1px 1px black" }}
              >
                {/* <FormattedMessage id="contact" /> */}
                {data?.smallDescription}
              </div>
            </div>
          </div>

          <div className="container pt-8 pb-12">
            <section className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col w-full md:w-2/3">
                {categoriesBlogs.length > 0 ? (
                  <PaginatedItems
                    items={categoriesBlogs}
                    ItemComponent={BlogH}
                    itemsPerPage={4}
                  />
                ) : (
                  <div className="h-full flex">
                    <div>
                      <FormattedMessage id="no.information" />
                    </div>
                  </div>
                )}
              </div>
              <div className="pl-4 w-full md:w-1/3">
                <TagBlog />
                <TrendingBlogs />
              </div>
            </section>
          </div>
        </>
      ) : (
        <div className="h-full">
          <ErrorPage />
        </div>
      )}
    </Layout>
  );
};

export default BlogCategory;
