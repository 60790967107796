import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorPage = () => {
    return (
        <div className="flex items-center px-5 lg:px-15 py-16 overflow-hidden relative">
            <div className="flex-1 min-w-full rounded-lg p-10 lg:p-10 text-gray-800 relative md:flex items-center text-center md:text-left">
                <div className="w-full md:w-1/2">
                    <div className="mb-4 md:mb-10 flex flex-col items-center justify-center font-light">
                        <h1 className="font-black uppercase text-2xl lg:text-3xl text-brand mb-10">
                            <FormattedMessage id="you.seem.to.be.lost" />
                        </h1>
                        <p className="font-medium text-base">
                            <FormattedMessage id="the.page.you.are.looking" />
                        </p>
                    </div>
                    {/* <div className="mb-10 md:mb-0">
                        <Link
                            to="/"
                            className="inline-flex items-center px-4 py-2 border rounded-md shadow-md text-sm font-medium bg-brand hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-laRioja-400"
                        >
                            Go to Home
                        </Link>
                    </div> */}
                </div>
                {/* <div className="w-full md:w-1/2 text-center">
                    <img
                        className="object-contain w-full h-64"
                        src={notFound}
                        alt="Page Not Found"
                    />
                </div> */}
            </div>
        </div>
    );
};

export default ErrorPage;
