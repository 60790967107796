import { Check, ChevronDown } from "lucide-react";
import React, { useState } from "react";
import { Button } from "../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";
import { useIntl } from "react-intl";

const SearchSelect = ({ data, onSelected, valueSeleted }) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const onSelect = (currentValue) => {
    onSelected(currentValue === valueSeleted ? "" : currentValue);
    setOpen(false);
  };

  return (
    <div className="w-full">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between focus:ring-light"
          >
            {valueSeleted
              ? data.find((item) => item === valueSeleted)
              : `${intl.formatMessage({ id: "select" })}`}
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="PopoverContent p-0">
          <Command>
            <CommandInput placeholder="Search ..." />
            <CommandList>
              <CommandEmpty>
                {intl.formatMessage({ id: "no.subject.found" })}
              </CommandEmpty>
              <CommandGroup>
                {data.map((item, i) => (
                  <CommandItem
                    key={i}
                    value={item}
                    onSelect={(currentValue) => onSelect(currentValue)}
                    className="cursor-pointer flex justify-between font-medium"
                  >
                    {item}
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        valueSeleted === item ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SearchSelect;
