import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { logo, logoWhite } from "../../assets";
import { usefulLinks } from "../../constants/footer";
import { useFooter, useMenu } from "../../redux/hooks/hooks";

const Footer = () => {
  const d = new Date();
  const location = useLocation();
  const data = useFooter();
  const intl = useIntl();
  const menus = useMenu();

  return (
    <footer className="border-t footer-wrapper">
      <div className="py-24">
        <div className="container">
          <div className="flex flex-wrap max-w-full">
            <div className="w-full  sm:w-1/2 md:w-[25%]">
              <div className="flex h-full items-center">
                <div className="flex flex-col gap-4 items-center w-full h-full pb-2 justify-center">
                  <NavLink to={"/"}>
                    <img
                      className="block w-auto h-36"
                      src={
                        data?.logo ? data?.logo : logoWhite //urlFor(data?.logo).fit("max").url()
                      }
                      alt="Lewoo"
                    />
                  </NavLink>
                  {/* <div className="flex font-[700] text-center text-[1rem] text-white">
                    {data?.slogan}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex items-center w-full  mt-6 sm:mt-0 md:w-[50%]">
              <div className="w-full">
                <h3 className="mb-2 font-[500] sm:mb-4 text-center text-white text-[1rem]">
                  <FormattedMessage
                    id="useful.links"
                    defaultMessage="Useful links"
                  />
                </h3>
                <ul className="text-sm list-none text-center w-full flex flex-col gap-2">
                  {usefulLinks.map((item) => (
                    <li key={item.id} className="my-1">
                      <NavLink
                        to={`${intl.formatMessage({ id: item.href })}`}
                        className={`rounded-sm text-white text-[0.85rem] hover:opacity-60  border-white border-b-1 ${location.pathname ===
                            intl.formatMessage({ id: item.href })
                            ? "border-white border-b font-semibold"
                            : ""
                          }`}
                      >
                        <FormattedMessage
                          id={item.id}
                          defaultMessage={item.name}
                        />
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex flex-col  items-center w-full  mt-6 md:mt-0 sm:w-1/2 md:w-[25%]">
              <h3 className="mb-2 font-[500] sm:mb-4 text-white text-[1rem]">
                <FormattedMessage id="follow.us" />
              </h3>
              <div className="text-sm text-center list-none w-full justify-center md:justify-normal">
                <ul className="text-white flex flex-wrap justify-center gap-8">
                  {data?.social?.map((item, index) => (
                    <li key={index} className="my-2 text-base">
                      <a
                        href={item?.url}
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-sm text-white hover:text-brand"
                      >
                        <img
                          src={item?.image}
                          width={20}
                          height={20}
                          className="object-cover"
                          alt={item?.url}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 bg-black-150">
        <div className="container">
          <div className="overflow-x-hidden">
            <div className="flex flex-wrap">
              <div className="flex items-center w-full ">
                <p className="font-medium text-center text-white">
                  &copy; {d.getFullYear()} {` - `}
                  {data?.copyRight}
                  {" - "}
                  {data?.slogan}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
