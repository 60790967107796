import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { useLanguage } from "../../context";
import { getDatePost2 } from "../../helpers/utils";
import { useNewsDefault } from "../../redux/hooks/hooks";
import { setLocalStorage, slugCategory,slugBlog } from "../../helpers/localStorage";

const NewsHome = () => {
  const data = useNewsDefault();
  const { language } = useLanguage();
  const intl = useIntl();

  const onPress = (data) => {
    setLocalStorage(slugCategory, data?.category?.slug?.en?.current);
    setLocalStorage(slugBlog, data?.slug?.en?.current);
  };

  return (
    <div
      className="w-full md:w-2/3 pt-2"
      // data-aos="fade-up"
      // data-aos-duration="200"
      // data-aos-easing="ease-in"
    >
      <span className="font-semibold text-[18px]">
        <FormattedMessage id="newss" />
      </span>
      <div className="flex flex-col gap-y-8 pt-4 pb-5">
        {data?.news?.map((item, index) => (
          <div key={index} className="flex flex-col md:flex-row md:gap-5 gap-3">
            <NavLink
              to={`${intl.formatMessage({
                id: "link.blog",
              })}/${item?.slug?.en?.current}`}
              className="h-[120px] md:w-2/5 md:h-[100px] w-full flex bg-center bg-cover"
              style={{
                backgroundImage: `url(${urlFor(item.image).fit("max").url()})`,
              }}
              onClick={() => onPress(item)}
            />
            <div className="w-full md:w-3/5">
              <NavLink
                to={`${intl.formatMessage({
                  id: "link.blog",
                })}/${item?.slug?.en?.current}`}
                className="block  font-semibold text-[0.85rem] cursor-pointer hover:opacity-70"
                onClick={() => onPress(item)}
              >
                {item.title}
              </NavLink>
              <span className="block text-gray-400 text-[0.85rem] pt-3">
                {getDatePost2(item.date, language)}
              </span>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="py-5 flex justify-end">
        <NavLink
          to={`${intl.formatMessage({
            id: "link.news",
          })}`}
          className="text-primary border border-primary rounded-md p-3 flex gap-2 transition ease-in-out delay-150 duration-300 hover:font-[900] hover:bg-primary hover:text-white"
        >
          <FormattedMessage id="see.more" />{" "}
          <LuMoveRight className="w-[1.5rem] h-[1.5rem]" />
        </NavLink>
      </div> */}
    </div>
  );
};

export default NewsHome;
