import imageUrlBuilder from "@sanity/image-url";
import { client } from "./sanity";

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source);
}

// uses GROQ to query content: https://www.sanity.io/docs/groq
export const getContent = async (
  language,
  query,
  slug = null,
  slugMenu = null,
  slugSubMenu = null
) => {

  // console.log('query ---- ',query);
  // console.log('slug ---- ',slug);
  // console.log('slugMenu ---- ',slugMenu);
  // console.log('slugSubMenu ---- ',slugSubMenu);
  
  try {
    const data =
      slug && slugMenu && !slugSubMenu
        ? await client.fetch(query(language, slug, slugMenu))
        : slug && slugMenu && slugSubMenu
          ? await client.fetch(query(language, slugSubMenu, slugMenu, slug))
          : await client.fetch(query(language));
    // console.log("data --- 1 ", data);
    return data;
  } catch (e) {
    console.log("error ---- 2", e);
  }
};

export const getContentBySlug = async (
  language,
  query,
  slug,
  slugMenu = null
) => {
  try {
    const data = await client.fetch(query(language, slugMenu, slug));
    console.log("data --- 1 ", data);
    return data;
  } catch (e) {
    console.log("error ---- 2", e);
  }
};
