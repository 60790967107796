import React, { useState } from "react";
import { useIntl } from "react-intl";
import { onToastError, onToastSuccess } from "../../helpers/utils";
import { useNewsLetter } from "../../redux/hooks/hooks";

const Newsletter = () => {
  const data = useNewsLetter();
  const intl = useIntl();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [email, setEmail] = useState("");

  const isValidEmail = () => {
    if (email === "") {
      onToastError(intl.formatMessage({ id: "email.required" }));
      return false;
    }
    if (!emailRegex.test(email)) {
      onToastError(intl.formatMessage({ id: "email.invalid" }));
      return false;
    }
    return true;
  };

  const onSubscribe = () => {
    if (isValidEmail()) {
      onToastSuccess(intl.formatMessage({ id: "subscribed" }));
      setEmail("");
    }
  };

  return (
    <div className="newsletter flex flex-col gap-5 py-5 px-3">
      <div className="flex flex-row justify-center items-center text-white text-center">
        {/* <FormattedMessage id="subscribe.to.our.newsletter" /> */}
        {data?.title}
      </div>
      <div className="flex flex-col gap-5">
        <input
          type="email"
          className="text-[0.85rem] rounded-[5rem] px-4 py-2 border-0 focus:outline-none focus:ring-secondary focus:border-secondary"
          id="email"
          // placeholder={intl.formatMessage({ id: "email" })}
          placeholder={data?.input}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="bg-white rounded-[5rem] border-0 py-2 text-light font-[500] text-center"
          onClick={() => onSubscribe()}
        >
          {/* <FormattedMessage id="subscribe" /> */}
          {data?.button}
        </button>
      </div>
    </div>
  );
};

export default Newsletter;
