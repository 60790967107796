import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  queryAbout,
  queryContactus,
  queryHome,
  queryNews,
  queryProducts,
  queryService,
  querySlider,
  querySubMenuPage
} from "../api/sanity/query";
import { setAboutData } from "../redux/reducers/about";
import { setContactusData } from "../redux/reducers/contactus";
import { setHomeData } from "../redux/reducers/home";
import { setNewsData } from "../redux/reducers/news";
import { setProductsData } from "../redux/reducers/product";
import { setServiceData } from "../redux/reducers/service";
import { setSliderPageData } from "../redux/reducers/sliderPage";
import { setSubMenuPageData } from "../redux/reducers/subMenuPage";
import { getCookie, setCookie } from "./localStorage";
const locales = {
  en: enUS,
  fr: fr,
};

export const onChangeInputFields = (setFields, key, text) => {
  setFields((prevFields) => ({
    ...prevFields,
    [key]: text,
  }));
};

export const getDatePost = (date) => {
  const d = new Date(`${date}`).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return d ?? "";
};

export const getDatePost2 = (date, lang) => {
  const formattedDate = format(new Date(date), "MMM d, yyyy", {
    locale: locales[lang],
  });
  return formattedDate ?? "";
};
export const getDatePost3 = (date, lang) => {
  const formattedDate = format(new Date(date), "MMMM d, yyyy", {
    locale: locales[lang],
  });
  return formattedDate ?? "";
};

export const onToastError = (message) => {
  toast.error(message, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const onToastSuccess = (message) => {
  toast.success(message, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;

    // Clean up function
    return () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, [title, prevailOnUnmount]);
};

export const onSubscription = (
  navigate,
  product,
  linkSubscription,
  linkSignIn
) => {
  setCookie("onSelectProduct", product);
  if (getCookie("token") && getCookie("userId")) {
    navigate(linkSubscription);
  } else {
    setCookie("locationSign", linkSubscription);
    navigate(linkSignIn);
  }
};

export const getColor = (color) => {
  // console.log("color --- ", color);
  return `${color}`.toString().trim().toLowerCase();
};

export const getUrlOpposite = (url) => {
  console.log("url -- ", url);
  switch (url) {
    case "/about":
      return "/a-propos";
    case "/a-propos":
      return "/about";
    case "/news":
      return "/actualites";
    case "/actualites":
      return "/news";
    case "/products":
      return "/produits";
    case "/produits":
      return "/products";
    case "/services":
      return "/fr/services";
    case "/fr/services":
      return "/services";
    case "/info":
      return "/fr/info";
    case "/fr/info":
      return "/info";
    case "/contact":
      return "/fr/contact";
    case "/fr/contact":
      return "/contact";
    default:
      return url;
  }
};

function getLastSegment(url) {
  const segments = url.split("/");
  return segments[segments.length - 1];
}

function getBeforeLastSegment(url, int) {
  const segments = url.split("/");
  return segments[segments.length - int];
}

export const getUrlOppositeBlog = (url) => {
  if (
    `${url}`.startsWith("/blog") &&
    !`${url}`.startsWith("/blog/categories")
  ) {
    return `/fr/blog/${getLastSegment(`${url}`)}`;
  }
  if (
    `${url}`.startsWith("/fr/blog") &&
    !`${url}`.startsWith("/blog/categories") &&
    !`${url}`.startsWith("/fr/blog/categories")
  ) {
    return `/blog/${getLastSegment(`${url}`)}`;
  }
  if (
    `${url}`.startsWith("/blog/categories") &&
    !`${url}`.startsWith("/fr/blog/categories")
  ) {
    return `/fr/blog/categories/${getLastSegment(`${url}`)}`;
  }
  if (`${url}`.startsWith("/fr/blog/categories")) {
    return `/blog/categories/${getLastSegment(`${url}`)}`;
  }

  if (`${url}`.startsWith("/info") && !`${url}`.startsWith("/fr/info")) {
    return `/fr/info/${getBeforeLastSegment(`${url}`, 2)}/${getLastSegment(
      `${url}`
    )}`;
  }
  if (`${url}`.startsWith("/fr/info")) {
    return `/info/${getBeforeLastSegment(`${url}`, 2)}/${getLastSegment(
      `${url}`
    )}`;
  }

  if (`${url}`.startsWith("/slider") && !`${url}`.startsWith("/fr/slider")) {
    return `/fr/slider/${getLastSegment(`${url}`)}`;
  }
  if (`${url}`.startsWith("/fr/slider")) {
    return `/slider/${getLastSegment(`${url}`)}`;
  }

  if (`${url}`.startsWith("/service") && !`${url}`.startsWith("/fr/service")) {
    return `/fr/service/${getLastSegment(`${url}`)}`;
  }
  if (`${url}`.startsWith("/fr/service")) {
    return `/service/${getLastSegment(`${url}`)}`;
  }

  if (`${url}`.startsWith("/sub") && !`${url}`.startsWith("/fr/sub")) {
    return `/fr/sub/${getBeforeLastSegment(`${url}`, 3)}/${getBeforeLastSegment(
      `${url}`,
      2
    )}/${getLastSegment(`${url}`)}`;
  }
  if (`${url}`.startsWith("/fr/sub")) {
    return `/sub/${getBeforeLastSegment(`${url}`, 3)}/${getBeforeLastSegment(
      `${url}`,
      2
    )}/${getLastSegment(`${url}`)}`;
  }

  return url;
};

export const getCurrentLanguage = (url, language) => {
  if (url === "/about" && language === "fr") {
    return "en";
  }
  if (url === "/a-propos" && language === "en") {
    return "fr";
  }
  if (url === "/produits" && language === "en") {
    return "fr";
  }
  if (url === "/products" && language === "fr") {
    return "en";
  }
  if (url === "/news" && language === "fr") {
    return "en";
  }
  if (url === "/actualites" && language === "en") {
    return "fr";
  }
  if (url === "/services" && language === "fr") {
    return "en";
  }
  if (url === "/fr/services" && language === "en") {
    return "fr";
  }
  if (url === "/contact" && language === "fr") {
    return "en";
  }
  if (url === "/fr/contact" && language === "en") {
    return "fr";
  }
  if (`${url}`.startsWith("/fr/blog") && language === "en") {
    return "fr";
  }
  if (`${url}`.startsWith("/blog") && language === "fr") {
    return "en";
  }

  if (`${url}`.startsWith("/fr/blog/categories") && language === "en") {
    return "fr";
  }

  if (`${url}`.startsWith("/blog/categories") && language === "fr") {
    return "en";
  }

  if (`${url}`.startsWith("/fr/info") && language === "en") {
    return "fr";
  }
  if (`${url}`.startsWith("/info") && language === "fr") {
    return "en";
  }

  if (`${url}`.startsWith("/fr/sub") && language === "en") {
    return "fr";
  }
  if (`${url}`.startsWith("/sub") && language === "fr") {
    return "en";
  }

  if (`${url}`.startsWith("/fr/slider") && language === "en") {
    return "fr";
  }
  if (`${url}`.startsWith("/slider") && language === "fr") {
    return "en";
  }
  if (`${url}`.startsWith("/fr/service") && language === "en") {
    return "fr";
  }
  if (`${url}`.startsWith("/service") && language === "fr") {
    return "en";
  }

  console.log("language --", language);
  return language;
};

export const queryAndSet = async (url) => {
  console.log("queryAndSet --------- ", url);
  if (url === "/") {
    return { query: queryHome, setData: setHomeData };
  }
  if (url === "/about" || url === "/a-propos") {
    return { query: queryAbout, setData: setAboutData };
  }
  if (
    url === "/services" ||
    url === "/fr/services" ||
    `${url}`.startsWith("/service") ||
    `${url}`.startsWith("/fr/service")
  ) {
    return { query: queryService, setData: setServiceData };
  }
  if (url === "/contact" || url === "/fr/contact") {
    return { query: queryContactus, setData: setContactusData };
  }
  if (url === "/products" || url === "/produits") {
    return { query: queryProducts, setData: setProductsData };
  }
  if (
    url === "/news" ||
    url === "/actualites" ||
    `${url}`.startsWith("/blog/categories") ||
    `${url}`.startsWith("/blog") ||
    `${url}`.startsWith("/fr/blog/categories") ||
    `${url}`.startsWith("/fr/blog")
  ) {
    return { query: queryNews, setData: setNewsData };
  }

  if (`${url}`.startsWith("/info") || `${url}`.startsWith("/fr/info")) {
    return {
      query: querySubMenuPage,
      setData: setSubMenuPageData,
      slug: getLastSegment(url),
      slugMenu: getBeforeLastSegment(url, 2),
    };
  }

  if (`${url}`.startsWith("/sub") || `${url}`.startsWith("/fr/sub")) {
    return {
      query: querySubMenuPage,
      setData: setSubMenuPageData,
      slug: getLastSegment(url),
      sulgSubMmenu: getBeforeLastSegment(url, 2),
      slugMenu: getBeforeLastSegment(url, 3),
    };
  }

  if (`${url}`.startsWith("/slider") || `${url}`.startsWith("/fr/slider")) {
    return {
      query: querySlider,
      setData: setSliderPageData,
    };
  }
};

export const getTitle = (intl, url) => {
  if (url === "/") {
    return intl.formatMessage({ id: "home" });
  }
  if (url === "/about" || url === "/a-propos") {
    return intl.formatMessage({ id: "about" });
  }
  if (url === "/services" || url === "/fr/services") {
    return intl.formatMessage({ id: "services" });
  }
  if (url === "/contact" || url === "/fr/contact") {
    return intl.formatMessage({ id: "contact" });
  }
  if (url === "/news" || url === "/actualites") {
    return intl.formatMessage({ id: "news" });
  }
  if (url === "/products" || url === "/produits") {
    return intl.formatMessage({ id: "products" });
  }
  if (`${url}`.startsWith("/blog") || `${url}`.startsWith("/fr/blog")) {
    const u = getLastSegment(url);
    return u ? u : intl.formatMessage({ id: "blog" });
  }
  if (
    `${url}`.startsWith("/blog/categories") ||
    `${url}`.startsWith("/fr/blog/categories")
  ) {
    const u = getLastSegment(url);
    return u ? u : intl.formatMessage({ id: "blog.categories" });
  }
  if (`${url}`.startsWith("/info") || `${url}`.startsWith("/fr/info")) {
    const u = getLastSegment(url);
    return u ? u : "Informations";
  }

  if (`${url}`.startsWith("/sub") || `${url}`.startsWith("/fr/sub")) {
    const u = getLastSegment(url);
    return u ? u : "Informations";
  }

  if (`${url}`.startsWith("/service") || `${url}`.startsWith("/fr/service")) {
    const u = getLastSegment(url);
    return u ? u : "Services";
  }

  if (`${url}`.startsWith("/slider") || `${url}`.startsWith("/fr/slider")) {
    const u = getLastSegment(url);
    return u ? u : "Informations";
  }

  return "";
};

export const getBlogsByCategory = (blogs, slug) => {
  return blogs.filter(
    (blog) => blog.category && blog.category.slug["en"]["current"] === slug
  );
};

export const getRelatedBlog = (blogs, slugCategory, slugBlog) => {
  // console.log("slugBlog -- 222", slugBlog);
  return blogs.filter(
    (blog) =>
      blog.category &&
      blog.category.slug["en"]["current"] === slugCategory &&
      blog.slug["en"]["current"] !== slugBlog
  );
};

export const getBlogBySlug = (blogs, targetSlug) => {
  return blogs.find((blog) => blog.slug["en"]["current"] === targetSlug);
};

export const getDataBySlug = (data, targetSlug) => {
  return data.find((elt) => elt.slug["en"]["current"] === targetSlug);
};

export const getCategoryBySlug = (categories, targetSlug) => {
  return categories.find((cat) => cat.slug["en"]["current"] === targetSlug);
};

export const filterBlogsBySearch = (blogs, searchQuery) => {
  const sanitizedQuery = searchQuery.trim().toLowerCase();
  return blogs.filter((blog) =>
    blog.title.toLowerCase().includes(sanitizedQuery)
  );
};

export const getSliderBySlug = (data, targetSlug) => {
  return data.find((slide) => slide.slug["current"] === targetSlug);
};

export const  filterSubmenusInMenus = (menus, submenus)=> {
  const submenuSlugsInMenus = new Set();

  // Parcourir les menus pour collecter les slugs des sous-menus
  menus.forEach(menu => {
    if (menu.submenus) {
      menu.submenus.forEach(submenu => {
        submenuSlugsInMenus.add(submenu.slug.en.current);
      });
    }
  });

  // Filtrer les sous-menus pour ne garder que ceux qui sont dans les menus
  return submenus.filter(submenu => submenuSlugsInMenus.has(submenu.slug.en.current));
}


export const  findMenusWithSubmenu = (menus, submenuSlug)=> {
  return menus.filter(menu => 
    menu.submenus && menu.submenus.some(submenu => submenu.slug.en.current === submenuSlug)
  );
}

export const getMenuBySlug = (data, targetSlug) => {
  return data.find((slide) => slide.slug.en.current === targetSlug);
};

export function sortByUpdatedAt(arr) {
  return arr.sort((a, b) => new Date(b._updatedAt) - new Date(a._updatedAt));
}