import * as React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { cn } from "../../../lib/utils";
import { useMenu } from "../../../redux/hooks/hooks";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger
} from "../../ui/navigation-menu";

const MegaMenu = () => {
  const menus = useMenu();
  const intl = useIntl();

  return (
    <NavigationMenu className="md:!max-w-none justify-end">
      <NavigationMenuList>
        {menus?.map((menu, index) => (
          <NavigationMenuItem key={index}>
            <NavigationMenuTrigger className="!px-2 text-light">
              {menu?.title}
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              {menu?.submenus?.length > 0 && (
                <ul className="grid gap-3 p-5 md:grid-cols-2">
                  {menu?.submenus.map((submenu, index) => (
                    <li key={index}>
                      <NavigationMenuLink asChild>
                        <NavLink
                          to={`${intl.formatMessage({
                            id: "link.info",
                          })}/${menu?.slug?.en?.current}/${
                            submenu?.slug?.en?.current
                          }`}
                          className={cn(
                            "block select-none rounded-md p-3 no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                          )}
                        >
                          <div className="text-[0.85rem] leading-none text-light">
                            {submenu.title}
                          </div>
                          <hr className="w-10 h-1 bg-light mt-2" />
                          <p className="leading-snug text-[0.8rem] mt-2 text-black/80 py-2">
                            {submenu.description}
                          </p>
                        </NavLink>
                      </NavigationMenuLink>
                    </li>
                  ))}
                </ul>
              )}
            </NavigationMenuContent>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default MegaMenu;
