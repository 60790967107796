import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useNews } from "../../redux/hooks/hooks";

const TagBlog = () => {
  const data = useNews();
  const intl = useIntl();
  return (
    <div className="my-5">
      <div className="flex justify-start py-4">
        <span className="font-[700] text-light">
          <i className="fas fa-circle"></i>{' '}Categories
        </span>
      </div>
      <div
        className="p-[20px] border border-light-border"
        // data-aos="fade-up"
        // data-aos-duration="600"
        // data-aos-easing="ease-out"
      >
        <div className="flex gap-5 flex-wrap tags-list">
          {data?.categoriesBlogs?.map((item, index) => (
            <NavLink
              to={`${intl.formatMessage({
                id: "link.blog.categories",
              })}/${item?.slug["en"]["current"]}`}
              key={index}
              className="bg-light-100 rounded-[20px] font-[600] text-light text-[12px] py-[3px] px-[18px] hover:bg-light hover:text-white"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TagBlog;
