import React from "react";
import { Grid } from "react-loader-spinner";

function Loader() {
  return (
    <div className="flex items-center justify-center py-[10rem] px-4 sm:px-6 lg:px-8">
      <Grid
        height="25"
        width="25"
        color="#4fa94d"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}
export default Loader;
