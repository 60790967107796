import React, { useEffect, useState } from "react";
import { LuMoveRight } from "react-icons/lu";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { getMenuBySlug } from "../../helpers/utils";
import { useProduct } from "../../redux/hooks/hooks";

const ContentProduct = () => {
    const data = useProduct();
    const [menu, setMenu] = useState();
    const intl = useIntl();

    useEffect(() => {
        if (data?.menus) {
            setMenu(getMenuBySlug(data?.menus, "products"));
        }
    }, [data]);

    // console.log("products -- ", menu?.submenus);

    return (
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2 w-full">
            {menu &&
                menu?.submenus &&
                menu?.submenus?.map((product, index) => (
                    <div key={index} className="w-full flex flex-col gap-3">
                        <div className="px-2 py-4 w-full bg-gray-100">
                            <img
                                src={urlFor(product?.banner).fit("max").url()}
                                className="w-full h-[15rem] md:h-[12rem] object-contain"
                                alt={product?.title}
                            />
                        </div>
                        <span className="font-[700] text-[1rem]">{product.title}</span>
                        <p className="font-[400] text-justify">{product.description}</p>
                        <div className="py-5 flex justify-end">
                            <NavLink
                                to={`${intl.formatMessage({
                                    id: "link.info",
                                })}/${menu?.slug?.en?.current}/${product?.slug?.en?.current}`}
                                className="text-light border border-light rounded-md p-3 flex gap-2 transition ease-in-out delay-150 duration-300 hover:font-[900] hover:bg-light hover:text-white"
                            >
                                <FormattedMessage id="read.more" />{" "}
                                <LuMoveRight className="w-[1.5rem] h-[1.5rem]" />
                            </NavLink>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ContentProduct;
