export const queryHome = (language) => {
  return `*[_type == "homePage"][0]{
        title,
        "meta":meta.${language},
        "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
        "slider":slider[]->{slug,"text":name.${language},"image":image.asset->url,"textSlider":textSlider.${language}},
        "firstBlock":block1[]->{"title":name.${language},"description":description.${language}},
        "secondBlock":block2[]->{"title":name.${language},"description":description.${language},"image":image.asset->url,"images":images[].asset->url,isProduct},
        "newsDefault":{"news":*[_type == "blog"] | order(_updatedAt desc){_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},date,_updatedAt} [0...3]},
        "partners":partners[]->{title,url,"image":image.asset->url},
        "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
        "newletter":*[_type == "newletterPage"][0]{"title":title.${language},"input":input.${language},"button":button.${language},"color":backgroundColor.hex}, 
        "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},
        "sliders":*[_type == "submenu" && isSlider == true][]{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},isExperienceMenu,isOfferMenu,_updatedAt} | order(_updatedAt desc)
      }`;
  //"newsDefault":{"news":news[]->{_id,"slug":slug,"title":title.${language},"category":category->{_id,"title":title.${language}},"image":image.asset->url,date}},
};

export const querySlider = (language) => {
  return `*[_type == "homePage"][0]{
        "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
        "slider":slider[]->{slug,"text":name.${language},"image":image.asset->url,"textSlider":textSlider.${language},"content":${contentPortableText(
    language,
    1
  )},"color":color.hex},
        "newsDefault":{"news":*[_type == "blog"] | order(_updatedAt desc){_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},date,_updatedAt} [0...3]},
        "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
        "newletter":*[_type == "newletterPage"][0]{"title":title.${language},"input":input.${language},"button":button.${language},"color":backgroundColor.hex}, 
        "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},

      }`;
  //"newsDefault":{"news":news[]->{_id,"slug":slug,"title":title.${language},"category":category->{_id,"title":title.${language}},"image":image.asset->url,date}},
};

export const queryAbout = (language) => {
  return `*[_type == "aboutPage"][0]{
          "meta":meta.${language},
          "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
          "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
          "statsBlock":statsBlock[]{"title": title.${language},value},
          "description":description.${language},
          "hero":hero->{_id,"title":title.${language},"description":description.${language},"image":image.asset->url},
          "newletter":*[_type == "newletterPage"][0]{"title":title.${language},"input":input.${language},"button":button.${language},"color":backgroundColor.hex},
          "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},

        }
      `;
};

export const queryService = (language) => {
  return `*[_type == "servicePage"][0]{
        "meta":meta.${language},
        "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
        "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
        "services":*[_type == "service"]{_id,"slug":slug,"title": title.${language},"image":image.asset->url,"smallDescription":smallDescription.${language},"description":description.${language}},
        "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},
        "hero":hero->{_id,"title":title.${language},"description":description.${language},"image":image.asset->url},
        "newletter":*[_type == "newletterPage"][0]{"title":title.${language},"input":input.${language},"button":button.${language},"color":backgroundColor.hex}, 
        }
      `;
};

export const queryContactus = (language) => {
  return `*[_type == "contactPage"][0]{
          "meta":meta.${language},
          "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location,"hero":hero->{_id,"title":title.${language},"description":description.${language},"image":image.asset->url},"subject":*[_type == "subject"][]{"title":title.${language},"categories":categories[].${language}}},
          "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
          "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},

          }
      `;
};

export const queryProducts = (language) => {
  return `*[_type == "productPage"][0]{
        "meta":meta.${language},
        "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
        "videos":videos[].url,
        "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
        "products":*[_type == "product"]{"title": title.${language},"image":image.asset->url,"description":description.${language},url},
        "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},
        "hero":hero->{_id,"title":title.${language},"description":description.${language},"image":image.asset->url},
        "newletter":*[_type == "newletterPage"][0]{"title":title.${language},"input":input.${language},"button":button.${language},"color":backgroundColor.hex}, 
        
      }
      `;
};

export const queryNews = (language) => {
  return `*[_type == "blogPage"][0]{
      "meta":meta.${language},
      "carrouselBlogs":carrousel[]->{_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language}},
      "recentsBlogs":*[_type == "blog"] | order(_updatedAt desc){_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},date,_updatedAt} [0...3],
      "popularBlogs":*[_type == "blog" && popular == true] | order(_updatedAt desc){_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},date,_updatedAt},
      "trendingBlogs":trending[]->{_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},_updatedAt,_createdAt,date}  | order(date desc),
      "categoriesBlogs":*[_type == "blogCategory"]{_id,"slug":slug,"title":title.${language},"image":image.asset->url,"description":description.${language},_updatedAt,_createdAt}  | order(title),
      "blogs":*[_type == "blog"]{_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},_updatedAt,_createdAt,date,"description":description.${language}}  | order(_updatedAt desc),      
      "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
      "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
      "newletter":*[_type == "newletterPage"][0]{"title":title.${language},"input":input.${language},"button":button.${language},"color":backgroundColor.hex},
      "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},

      }
      `;
  // "popularBlogs":popular[]->{_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},_updatedAt,_createdAt,date} | order(_updatedAt desc),
};

export const querySubMenuPage = (
  language,
  slug,
  slugMenu = null,
  slugSubmenu = null
) => {
  // console.log('submenu --- ',slugMenu)
  // console.log('slug --- ',slug)
  // console.log('slugSubmenu --- ',slugSubmenu)
  let re = `*[_type == "submenu" && slug.en.current == '${slug}'][0]{
        _id,
        slug,
        "title":title.${language},
        "meta":meta.${language},
        "description":description.${language},
        "contact":*[_type == "contactPage"][0]{"name": company,"logo":logo->image.asset->url,phone,fax,email,"address":address.${language},"postBox":postBox.${language},location},
        "footer":*[_type == "footerPage"][0]{"slogan":slogan.${language},"copyRight":copyRight.${language},"logo": image.asset->url ,"social":social[]->{title,url,"image":image.asset->url}}, 
        "menus":*[_type == "menu"]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex,"submenus":submenus[]->{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},_updatedAt} | order(_updatedAt desc),_updatedAt},
        "imagesMenu":imagesMenu[]->{_id,"slug":slug,"title": title.${language},"description":description.${language},"banner":banner.asset->url},
        "offer":offerMenu[]->{_id,"slug":slug,"title": title.${language},"description":description.${language},"banner":banner.asset->url},
        "experience":experienceMenu[]->{_id,"slug":slug,"title": title.${language},"description":description.${language},"banner":banner.asset->url},
        "banner":banner.asset->url,
        "content":${contentPortableText(language, 1)},
        "newsDefault":{"news":*[_type == "blog"] | order(_updatedAt desc){_id,"slug":slug,"title":title.${language},"category":category->{_id,"slug":slug,"title":title.${language}},"image":image.asset->url,"smallDescription":smallDescription.${language},date,_updatedAt} [0...3]},
        "menu":*[_type == "menu" && slug.en.current == '${slugMenu}'][0]{_id,"slug":slug,"title": title.${language},"color":backgroundColor.hex},
        "submenu":*[_type == "submenu" && slug.en.current == '${slugSubmenu}'][0]{_id,"slug":slug,"title": title.${language},"banner":banner.asset->url,"description":description.${language},"content":${contentPortableText(
    language,
    1
  )}}
      }
      `;

  // console.log("re -- ", re);
  return re;
};

export const contentPortableText = (language, depth) => {
  // Condition d'arrêt pour éviter une boucle infinie
  if (depth > 5) {
    return "[]"; // Arrêter la récursivité après 5 niveaux
  }

  return `content.${language}[]{
    ...,
    _type == 'blocks' => {
      ...,
      children[]{
        ...,
        _type == 'accordionList' => {
          accordion->{
            title,
            content[]{
              "title": title.${language},
              "content": ${contentPortableText(
    language,
    depth + 1
  )} // Appel récursif avec profondeur augmentée
            }
          }
        },
        _type == 'gridImage' => {
          grid->{
            title,
            content[]{
              ...,
              "image": image.asset->url
            }
          }
        },
        _type == 'resizeImage' => {
            ...,
            "image":image
          
        },
      }
    }
  }`;
};
