import { PortableText } from "@portabletext/react";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { components } from "../api/sanity/portableText";
import { ErrorPage, Layout, NewsHome } from "../components";
import { getSliderBySlug } from "../helpers/utils";
import { useSliderPage } from "../redux/hooks/hooks";

const SliderPage = () => {
  const data = useSliderPage();
  const params = useParams();
  const slider = getSliderBySlug(data?.slider || [], params?.slug);
  const intl = useIntl();

  return (
    <Layout data={data}>
      {slider ? (
        <div>
          <div
            style={
              slider?.color
                ? { backgroundColor: slider?.color }
                : { backgroundColor: "#000" }
            }
          >
            <div className="container py-2 pt-3 md:py-4">
              <div className="flex flex-col md:flex-row md:justify-between md:items-center px-2 md:px-0">
                <div className="text-white text-[1.5rem]">{slider?.text}</div>
              </div>
            </div>
            <div
              className="bg-gray-300 flex flex-col bg-no-repeat bg-cover bg-center w-full"
              style={{ backgroundImage: `url(${slider.image})` }}
            >
              <div className="container">
                <div className="flex flex-col justify-center h-60 relative">
                  <div
                    className="text-[1rem] text-white py-3 mb-2"
                    style={{ textShadow: "1px 1px 1px black" }}
                  >
                    {slider?.textSlider}
                  </div>
                  <hr
                    className="h-5  w-28 absolute bottom-0"
                    style={
                      slider?.color
                        ? { backgroundColor: slider?.color }
                        : { backgroundColor: "#000" }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="py-5">
              {slider?.content && (
                <PortableText
                  value={slider?.content}
                  components={components(false)}
                  onMissingComponent={false}
                />
              )}
            </div>

            <div className="flex gap-3">
              <NewsHome />
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full">
          <ErrorPage />
        </div>
      )}
    </Layout>
  );
};

export default SliderPage;
