import { UPDATE_MENU } from "../constants";

const initialState = null;

export const setMenuData = (data) => ({
    type: UPDATE_MENU,
    data,
});

export const menu = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MENU:
            return action.data;
        default:
            return state;
    }
};
