import emailjs from "@emailjs/browser";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SearchSelect, Spinner } from "..";
import {
  onChangeInputFields,
  onToastError,
  onToastSuccess,
} from "../../helpers/utils";
import { useContactus } from "../../redux/hooks/hooks";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Textarea } from "../ui/textarea";

const FormContactUs = () => {
  const [loader, setLoader] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const data = useContactus();
  const intl = useIntl();
  const regexPhone = /^(\+|\d)(\d+)$/;
  const [inputFields, setInputFields] = useState({
    type: "company",
  });

  // console.log("data  ----- ", data);
  // console.log("categories  ----- ", categories);

  useEffect(() => {
    if (data?.subject) {
      setSubjects(data?.subject?.map((item) => item.title));
    }
  }, [data]);

  useEffect(() => {
    if (inputFields?.subject) {
      const subject = data?.subject?.filter(
        (item) => item.title === inputFields?.subject
      )[0];
      onChangeInputFields(setInputFields, "category", "");
      setCategories(subject?.categories);
    }
  }, [inputFields?.subject]);

  const isCheck = () => {
    if (
      !inputFields["name"] ||
      (inputFields["name"] &&
        `${inputFields["name"]}`.trim().toString().length <= 0)
    ) {
      onToastError(intl.formatMessage({ id: "the.name.field.is.empty" }));
      return false;
    }

    if (
      !inputFields["email"] ||
      (inputFields["eamil"] &&
        `${inputFields["email"]}`.trim().toString().length <= 0)
    ) {
      onToastError(intl.formatMessage({ id: "the.email.field.is.empty" }));
      return false;
    }

    if (
      !inputFields["phone"] ||
      (inputFields["phone"] &&
        `${inputFields["phone"]}`.trim().toString().length <= 0)
    ) {
      onToastError(intl.formatMessage({ id: "the.phone.field.is.empty" }));
      return false;
    }
    if (
      inputFields["phone"] &&
      !regexPhone.test(`${inputFields["phone"]}`.trim())
    ) {
      onToastError(intl.formatMessage({ id: "wrong.number.format" }));
      return false;
    }
    if (
      !inputFields["subject"] ||
      (inputFields["subject"] &&
        `${inputFields["subject"]}`.trim().toString().length <= 0)
    ) {
      onToastError(intl.formatMessage({ id: "the.subject.field.is.empty" }));
      return false;
    }
    if (
      !inputFields["category"] ||
      (inputFields["category"] &&
        `${inputFields["category"]}`.trim().toString().length <= 0)
    ) {
      onToastError(intl.formatMessage({ id: "the.category.field.is.empty" }));
      return false;
    }
    if (
      !inputFields["message"] ||
      (inputFields["message"] &&
        `${inputFields["message"]}`.trim().toString().length <= 0)
    ) {
      onToastError(intl.formatMessage({ id: "the.message.field.is.empty" }));
      return false;
    }
    return true;
  };

  const onClean = () => {
    onChangeInputFields(setInputFields, "name", "");
    onChangeInputFields(setInputFields, "email", "");
    onChangeInputFields(setInputFields, "phone", "");
    onChangeInputFields(setInputFields, "subject", "");
    onChangeInputFields(setInputFields, "message", "");
    onChangeInputFields(setInputFields, "type", "company");
  };

  const onSuccess = () => {
    setLoader(false);
    onClean();
    onToastSuccess(intl.formatMessage({ id: "sended" }));
  };

  const onError = (message) => {
    setLoader(false);
    onToastError(intl.formatMessage({ id: "internal.problem" }));
  };

  const onSelectSubject = (value) => {
    onChangeInputFields(setInputFields, "subject", value);
  };
  const onSelectCategory = (value) => {
    onChangeInputFields(setInputFields, "category", value);
  };

  const sendEmail = () => {
    const templateParams = {
      from_name: inputFields["name"],
      name: inputFields["name"],
      email: inputFields["email"],
      phone: inputFields["phone"],
      subject: inputFields["subject"],
      category: inputFields["category"],
      message: inputFields["message"],
      type: inputFields["type"],
    };
    const YOUR_SERVICE_ID = "service_sdle9qx";
    const YOUR_TEMPLATE_ID = "template_t6pwisp";
    const YOUR_PUBLIC_KEY = "K7VlcZJX_lEp_TZe5";

    emailjs
      .send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, {
        publicKey: YOUR_PUBLIC_KEY,
      })
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          onSuccess();
        },
        (err) => {
          console.log("FAILED...", err);
          onError(err);
        }
      );
  };

  const onSend = () => {
    if (isCheck()) {
      setLoader(true);
      console.log("inputFields --- ", inputFields);
      sendEmail();
    }
  };

  console.log("inputFields --- ", inputFields);
  return (
    <div className="lg:pr-12">
      {/* type */}
      <div className="mt-6">
        <label htmlFor="type" className="block  font-medium text-black">
          <FormattedMessage id="what.are.you" defaultMessage="what.are.you" />
        </label>
        <div className="mt-2">
          <Select
            defaultValue="company"
            onValueChange={(value) =>
              onChangeInputFields(setInputFields, "type", value)
            }
            className="focus:!ring-light"
          >
            <SelectTrigger className="w-full">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="company">
                  {intl.formatMessage({ id: "company" })}
                </SelectItem>
                <SelectItem value="individual">
                  {intl.formatMessage({ id: "individual" })}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Name */}
      <div className="mt-6">
        <label htmlFor="name" className="block  font-medium text-black">
          <FormattedMessage id="name*" defaultMessage="Name*" />
        </label>
        <div className="mt-2">
          <Input
            type="text"
            id="name"
            value={inputFields["name"] ? inputFields["name"] : ""}
            onChange={(e) =>
              onChangeInputFields(setInputFields, "name", e.target.value)
            }
            autoComplete="off"
            className="w-full rounded-md border  focus-visible:!ring-light focus:outline-none focus:ring-light focus:border-light focus:z-10"
          />
        </div>
      </div>

      {/* Email */}
      <div className="mt-6">
        <label htmlFor="email" className="block  font-medium text-black">
          <FormattedMessage id="email*" defaultMessage="Email*" />
        </label>
        <div className="mt-2">
          <Input
            type="email"
            id="email"
            value={inputFields["email"] ? inputFields["email"] : ""}
            onChange={(e) =>
              onChangeInputFields(setInputFields, "email", e.target.value)
            }
            autoComplete="off"
            className="w-full rounded-md border  focus:outline-none focus-visible:!ring-light focus:ring-light focus:border-light focus:z-10"
          />
        </div>
      </div>

      {/* Phone */}
      <div className="mt-6">
        <label htmlFor="phone" className="block  font-medium text-black">
          <FormattedMessage id="phone.number*" defaultMessage="Phone number*" />
        </label>
        <div className="mt-2">
          <Input
            type="tel"
            id="phone"
            value={inputFields["phone"] ? inputFields["phone"] : ""}
            onChange={(e) =>
              onChangeInputFields(setInputFields, "phone", e.target.value)
            }
            autoComplete="off"
            className="w-full rounded-md border  focus-visible:!ring-light focus:outline-none focus:ring-light focus:border-light focus:z-10"
          />
        </div>
      </div>

      {/* Subject */}
      <div className="mt-6">
        <label htmlFor="subject" className="block  font-medium text-black">
          <FormattedMessage id="subject*" defaultMessage="Subject*" />
        </label>
        <div className="mt-2">
          <SearchSelect
            data={subjects}
            valueSeleted={inputFields["subject"]}
            onSelected={onSelectSubject}
          />
        </div>
      </div>

      {/* Categorie */}
      <div className="mt-6">
        <label htmlFor="category" className="block  font-medium text-black">
          <FormattedMessage id="category*" defaultMessage="Category*" />
        </label>
        <div className="mt-2">
          <SearchSelect
            data={categories}
            valueSeleted={inputFields["category"]}
            onSelected={onSelectCategory}
          />
        </div>
      </div>

      {/* Message */}
      <div className="mt-6">
        <label htmlFor="message" className="block  font-medium text-black">
          <FormattedMessage id="your.message*" />
        </label>

        <div className="mt-2">
          <Textarea
            value={inputFields["message"] ? inputFields["message"] : ""}
            onChange={(e) =>
              onChangeInputFields(setInputFields, "message", e.target.value)
            }
            className="w-full rounded-md border  focus:outline-none focus:ring-light focus-visible::ring-light focus:border-light focus:z-10"
          />
        </div>
      </div>

      <div className="md:flex mt-6 md:justify-end gap-4 items-center">
        <button
          onClick={() => onSend()}
          className={`inline-flex justify-center py-3 px-8 border border-transparent shadow-sm  font-medium rounded-md text-white bg-light hover:bg-hover-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-light ${loader ? "disabled:opacity-50" : ""
            }`}
          disabled={loader}
        >
          {loader ? (
            <div className="flex items-center">
              <Spinner />
              {intl.formatMessage({ id: "sending" })}
            </div>
          ) : (
            <FormattedMessage id="send" defaultMessage="Send" />
          )}
        </button>
      </div>
    </div>
  );
};

export default FormContactUs;
