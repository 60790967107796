import React, { useEffect, useState } from "react";
import { LuMoveRight } from "react-icons/lu";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { getMenuBySlug } from "../../helpers/utils";
import { useService } from "../../redux/hooks/hooks";

const ContentService = () => {
    const data = useService();
    const [menu, setMenu] = useState();
    const intl = useIntl();

    useEffect(() => {
        if (data?.menus) {
            setMenu(getMenuBySlug(data?.menus, "services"));
        }
    }, [data]);

    // console.log("services -- ", menu?.submenus);

    return (
        <div className="w-full md:w-2/3 pt-8">
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2 w-full">
                {menu &&
                    menu?.submenus &&
                    menu?.submenus?.map((service, index) => (
                        <div key={index} className="w-full flex flex-col gap-3">
                            <img
                                src={urlFor(service?.banner).fit("max").url()}
                                className="w-full h-[12rem] md:h-[10rem] object-cover"
                                alt={service?.title}
                            />
                            <span className="font-[700] text-[1rem]">{service.title}</span>
                            <p className="font-[400] text-justify text-[0.85rem]">
                                {service.description}
                            </p>
                            <div className="py-5 flex justify-end">
                                <NavLink
                                    to={`${intl.formatMessage({
                                        id: "link.info",
                                    })}/${menu?.slug?.en?.current}/${service?.slug?.en?.current}`}
                                    className="text-light border border-light rounded-md p-3 flex gap-2 transition ease-in-out delay-150 duration-300 hover:font-[900] hover:bg-light hover:text-white"
                                >
                                    <FormattedMessage id="read.more" />{" "}
                                    <LuMoveRight className="w-[1.5rem] h-[1.5rem]" />
                                </NavLink>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ContentService;
