import React from "react";
import { useNews } from "../../redux/hooks/hooks";
import BlogH from "./BlogH";
import { FormattedMessage } from "react-intl";

const PopularBlogs = () => {
  const data = useNews();
  return (
    <div
     
      // data-aos="fade-up"
      // data-aos-duration="1000"
      // data-aos-easing="ease-in"
    >
      <div className="font-[700] text-[1.2rem]">
        <FormattedMessage id="populars" />
      </div>
      <div className="w-full flex flex-col gap-4 pt-4">
        {data?.popularBlogs?.map((item, index) => (
          <BlogH key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default PopularBlogs;
