import { useSelector } from "react-redux";

export const useHome = () => useSelector((state) => state.home);
export const useFooter = () => useSelector((state) => state.footer);
export const useService = () => useSelector((state) => state.service);
export const useAbout = () => useSelector((state) => state.about);
export const useNews = () => useSelector((state) => state.news);
export const useContactus = () => useSelector((state) => state.contactus);
export const useProduct = () => useSelector((state) => state.product);
export const useMenu = () => useSelector((state) => state.menu);
export const useSubMenuPage = () => useSelector((state) => state.subMenuPage);
export const useNewsDefault = () => useSelector((state) => state.newsDefault);
export const useNewsLetter = () => useSelector((state) => state.newletter);
export const useSliderPage = () => useSelector((state) => state.sliderPage);
