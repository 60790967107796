import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useNews } from "../../redux/hooks/hooks";
import { setLocalStorage, slugBlog, slugCategory } from "../../helpers/localStorage";

const TrendingBlogs = () => {
  const data = useNews();
  const intl = useIntl();

  const onPress = (data) => {
    setLocalStorage(slugCategory, data?.category?.slug?.en?.current);
    setLocalStorage(slugBlog, data?.slug?.en?.current);
  };


  return (
    <div className="trending-posts">
      <div className="mb-5 text-light">
        <h3 className="font-[700]">
          <i className="fas fa-circle"></i>{" "}
          <FormattedMessage id="trending.this.week" />
        </h3>
      </div>
      <div className="p-[20px] border border-light-border flex flex-col gap-5">
        {data?.trendingBlogs?.map((item, index) => (
          <div className="flex sidebar-item" key={index}>
            <div className="w-[20%] h-[40px] flex justify-center items-center bg-light-600 num-left">
              <span className="text-center text-white font-[700] text-[1rem]">
                {index + 1}
              </span>
            </div>
            <div className="w-[80%] pl-5 content-right">
              <NavLink
                to={`${intl.formatMessage({
                  id: "link.blog",
                })}/${item?.slug["en"]["current"]}`}
                className="text-[0.85rem] text-black hover:text-black/70 font-[600]"
                onClick={() => onPress(item)}
              >
                {item.title}
              </NavLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingBlogs;
