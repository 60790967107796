import React from "react";
import { useNews } from "../../redux/hooks/hooks";
import BlogV from "./BlogV";
import { FormattedMessage } from "react-intl";

const RecentsBlogs = () => {
  const data = useNews();
  return (
    <div className="pt-8">
      <div className="font-[700] text-[1.2rem]">
        <FormattedMessage id="recents" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-2">
        {data?.recentsBlogs?.map((item, index) => (
          <BlogV key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default RecentsBlogs;
