import { Autoplay } from "swiper/modules";

import React from "react";
import { FormattedMessage } from "react-intl";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { urlFor } from "../../api/sanity/utils";
import { useHome } from "../../redux/hooks/hooks";

const PartenersHome = () => {
  const data = useHome();
  return (
    <div
      className="container py-8"
      // data-aos="fade-up"
      // data-aos-duration="800"
      // data-aos-easing="ease-in"
    >
      <span className="font-semibold text-[18px]">
        <FormattedMessage id="partners" />
      </span>
      <div className="py-6">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={15}
        slidesPerView={2}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          575: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 15,
          },
        }}
        className=""
      >
        {data?.partners?.map((partner, index) => (
          <SwiperSlide key={index}>
            <a
              href={partner?.url}
              target="_blank"
              rel="noreferrer"
              className="flex w-[10rem]  sm:w-[9rem]"
            >
              <img
                src={urlFor(partner?.image).fit("max").url()}
                className="lazyload w-full h-[5rem] sm:h-[7rem] object-contain"
                alt={partner?.title}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
    </div>
  );
};

export default PartenersHome;
