import React, { useState } from "react";
import ReactPaginate from "react-paginate";

const PaginatedItems = ({ items, itemsPerPage, ItemComponent }) => {
  //const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  function Items({ currentItems }) {
    return (
      <div className="flex flex-col gap-5">
        {currentItems &&
          currentItems.map((item, index) => (
            <ItemComponent data={item} key={index}/>
          ))}
      </div>
    );
  }

  return (
    <div className="afs-paginate">
      <Items currentItems={currentItems} />
      <ReactPaginate
        nextLabel="➡️"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="⬅️"
        renderOnZeroPageCount={null}
        marginPagesDisplayed={3}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};

export default PaginatedItems;
