import { PortableText } from "@portabletext/react";
import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { components } from "../api/sanity/portableText";
import { urlFor } from "../api/sanity/utils";
import {
  CallToAction,
  ContactInfo,
  ErrorPage,
  Layout,
  Newsletter,
} from "../components";
import { socials } from "../constants/pages";
import { getDataBySlug } from "../helpers/utils";
import { useService } from "../redux/hooks/hooks";

const ServiceDetail = () => {
  const data = useService();
  const params = useParams();
  const intl = useIntl();
  const service = getDataBySlug(data?.services || [], params?.slug);
  // console.log("services --- ", service);

  const url = window.location.href;
  return (
    <Layout data={data}>
      {data?.services && service ? (
        <>
          <div
            className="bg-gray-100 md:h-80 h-60 bg-no-repeat bg-cover bg-center w-full"
            style={{
              backgroundImage: `url(${urlFor(service?.image)
                .fit("max")
                .url()})`,
            }}
          >
            <div className="container h-full">
              <div className="flex flex-col gap-2 justify-center h-full">
                <NavLink
                  to={`${intl.formatMessage({
                    id: "link.services",
                  })}`}
                  className={"text-white font-[900] text-[2rem]"}
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  <FormattedMessage id="services" />
                </NavLink>
                <div
                  className="flex items-center font-[700] text-[1.5rem] text-white"
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  {service?.title}
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-4 pb-12">
            <div className="pb-8 flex flex-col md:flex-row relative">
              <div className="w-full md:w-3/5 md:pr-10">
                <div className="py-2 flex gap-2">
                  {socials &&
                    socials?.map((social, index) => (
                      <social.ShareButton
                        key={index}
                        url={url}
                        title={service?.title}
                        className="share-button"
                      >
                        <social.ShareButtonIcon size={30} round />
                      </social.ShareButton>
                    ))}
                </div>

                <div className="py-2 text-justify">
                  {service?.description && (
                    <PortableText
                      value={service?.description}
                      components={components(false)}
                      onMissingComponent={false}
                    />
                  )}
                </div>
                <div className="py-5 flex justify-start">
                  <NavLink
                    to={`${intl.formatMessage({
                      id: "link.services",
                    })}`}
                    className="text-light  p-3 flex hover:border hover:border-light rounded-md gap-2 transition ease-in-out delay-150 duration-300 hover:font-[900]"
                  >
                    <LuMoveLeft className="w-[1.5rem] h-[1.5rem]" />
                    <FormattedMessage id="back.to.service" />
                  </NavLink>
                </div>
              </div>
              <div className="w-full md:w-2/5 md:pl-10 pt-2">
                <ContactInfo />
                <div className="flex justify-center pt-8">
                  <CallToAction />
                </div>
                <div className="pt-8 md:px-2">
                  <Newsletter />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-full">
            <ErrorPage />
          </div>
        </>
      )}
    </Layout>
  );
};

export default ServiceDetail;
