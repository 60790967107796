import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

const CarrouselItem = ({ data }) => {
  const intl = useIntl();
  return (
    <div
      className="w-full flex h-full bg-center bg-cover"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <div className="container py-4">
        <div className="flex w-full flex-col gap-1 justify-center h-full">
          <NavLink
            to={`${intl.formatMessage({
              id: "link.blog",
            })}/${data?.slug?.en?.current}`}
            className="font-[700] text-[1.5rem] md:text-[2rem] text-white"
            style={{ textShadow: "1px 1px 1px black" }}
          >
            {data.title}
          </NavLink>
          <p className="font-[400] text-white">
            {data.smallDescription}
          </p>
          <NavLink
            to={`${intl.formatMessage({
              id: "link.blog.categories",
            })}/${data.category?.slug?.en?.current}`}
            className="font-[900] text-[1rem] text-light hover:text-light/50"
          >
            {data.category?.title}
          </NavLink>
          <div>
            <NavLink
              to={`${intl.formatMessage({
                id: "link.blog",
              })}/${data?.slug?.en?.current}`}
              className="font-[600] text-[0.9rem] text-white px-3 py-1 bg-light rounded-2xl hover:bg-light/80"
            >
              {intl.formatMessage({
                id: "read.more",
              })}
            </NavLink>
          </div>
        </div>
        {/* <div className="flex py-1 flex-col gap-1">
          <NavLink
            to={`${intl.formatMessage({
              id: "link.blog.categories",
            })}/${data.category?.slug?.en?.current}`}
            className="text-white font-[900] text-[1rem] bg-light px-4 py-1 rounded-[20px]"
          >
            {data.category?.title}
          </NavLink>          
        </div> */}
      </div>
    </div>
  );
};

export default CarrouselItem;
