import { UPDATE_SLIDER } from "../constants";

const initialState = null;

export const setSliderPageData = (data) => ({
  type: UPDATE_SLIDER,
  data,
});

export const sliderPage = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SLIDER:
      return action.data;
    default:
      return state;
  }
};
