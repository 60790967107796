import { PortableText } from "@portabletext/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { components } from "../api/sanity/portableText";
import { ErrorPage, Layout, NewsHome } from "../components";
import { useSubMenuPage } from "../redux/hooks/hooks";

const SubMenuPage = () => {
  const data = useSubMenuPage();
  const intl = useIntl();

  // console.log("data --- slug", data?.slug);
  // console.log("data --- submenu", data?.submenu?.slug);
  console.log("data --- Content", data?.content);
//   console.log("data --- submenu", data?.submenu);

  return (
    <Layout data={data}>
      {data && (
        <div>
          <div
            style={
              data?.menu && data?.menu?.color
                ? { backgroundColor: data?.menu?.color }
                : { backgroundColor: "#000" }
            }
          >
            <div id="accordionSubmenu" className="container py-2 pt-3 md:py-4">
              <div className="flex flex-col md:flex-row md:justify-between md:items-center px-2 md:px-0">
                <div className="text-white text-[1.5rem]">{data?.title}</div>
                <div className="flex items-center gap-4">
                  <NavLink
                    to={`${intl.formatMessage({
                      id: "link.info",
                    })}/${data?.menu?.slug?.en?.current}/${
                      data?.slug?.en?.current
                    }`}
                    className=" text-white"
                  >
                    <FormattedMessage id="summary" />
                  </NavLink>
                  <div id="offer">
                    <button
                      className="group relative cursor-pointer items-center border-0 px-3 py-1 text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-twe-collapse-collapsed])]:font-semibold [&:not([data-twe-collapse-collapsed])]:border-dotted [&:not([data-twe-collapse-collapsed])]:text-white [&:not([data-twe-collapse-collapsed])]:border"
                      type="button"
                      data-twe-collapse-init
                      data-twe-collapse-collapsed
                      data-twe-target="#collapseOffer"
                      aria-expanded="true"
                      aria-controls="collapseOffer"
                    >
                      <FormattedMessage id="offer" />
                    </button>
                  </div>
                  <div id="experience">
                    <button
                      className="group relative cursor-pointer items-center border-0 px-3 py-1 text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-twe-collapse-collapsed])]:font-semibold [&:not([data-twe-collapse-collapsed])]:border-dotted [&:not([data-twe-collapse-collapsed])]:text-white [&:not([data-twe-collapse-collapsed])]:border"
                      type="button"
                      data-twe-collapse-init
                      data-twe-collapse-collapsed
                      data-twe-target="#collapsExperience"
                      aria-expanded="true"
                      aria-controls="collapseExperience"
                    >
                      <FormattedMessage id="experience" />
                    </button>
                  </div>
                  <div id="contact">
                    <button
                      className="group relative cursor-pointer items-center border-0 px-3 py-1 text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none  [&:not([data-twe-collapse-collapsed])]:font-semibold [&:not([data-twe-collapse-collapsed])]:border-dotted [&:not([data-twe-collapse-collapsed])]:text-white [&:not([data-twe-collapse-collapsed])]:border"
                      type="button"
                      data-twe-collapse-init
                      data-twe-collapse-collapsed
                      data-twe-target="#collapsContact"
                      aria-expanded="true"
                      aria-controls="collapseContact"
                    >
                      <FormattedMessage id="contact" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                id="collapseOffer"
                className="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="offer"
                data-twe-parent="#accordionSubmenu"
              >
                <div className="px-5 py-6 flex flex-col gap-3">
                  {data?.offer?.map((offer, index) => (
                    <NavLink
                      key={index}
                      to={`${intl.formatMessage({
                        id: "link.sub",
                      })}/${data?.menu?.slug?.en?.current}/${
                        data?.slug?.en?.current
                      }/${offer?.slug?.en?.current}`}
                      className="border-dotted py-1 px-2  border-l-2 border-l-white text-white"
                    >
                      {offer?.title}
                    </NavLink>
                  ))}
                  
                </div>
              </div>
              <div
                id="collapsExperience"
                className="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="offer"
                data-twe-parent="#accordionSubmenu"
              >
                <div className="px-5 py-6 flex flex-col gap-3">
                  {data?.experience?.map((experience, index) => (
                    <NavLink
                      key={index}
                      to={`${intl.formatMessage({
                        id: "link.sub",
                      })}/${data?.menu?.slug?.en?.current}/${
                        data?.slug?.en?.current
                      }/${experience?.slug?.en?.current}`}
                      className="border-dotted py-1 px-2  border-l-2 border-l-white text-white"
                    >
                      {experience?.title}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div
                id="collapsContact"
                className="!visible hidden"
                data-twe-collapse-item
                aria-labelledby="offer"
                data-twe-parent="#accordionSubmenu"
              >
                <div className="px-5 py-6 flex gap-3 ">
                  <NavLink
                    className={"text-white cursor-pointer"}
                    style={{ textShadow: "1px 1px 1px black" }}
                    to={intl.formatMessage({ id: "link.contact" })}
                  >
                    Contact
                  </NavLink>
                </div>
              </div>
            </div>
            <div
              className="bg-gray-300 flex flex-col bg-no-repeat bg-cover bg-center w-full"
              style={{
                backgroundImage: `url(${
                  data?.submenu ? data?.submenu.banner : data.banner
                })`,
              }}
            >
              <div className="container">
                <div className="flex flex-col justify-end h-full relative  pt-[3rem] md:pt-[5rem] pb-8">
                  {/* {!data?.submenu && (
                                        <div className="font-[700] text-[1.5rem] text-white py-2">
                                            {data?.title}
                                        </div>
                                    )} */}
                  {data?.submenu && (
                    <div
                      className="font-[700] text-[1.5rem] text-white pb-2"
                      style={{ textShadow: "1px 1px 1px black" }}
                    >
                      {data?.submenu?.title}
                    </div>
                  )}
                  <div
                    className="text-[1.2rem] text-white py-3 mb-2"
                    style={{ textShadow: "1px 1px 1px black" }}
                  >
                    {data?.submenu
                      ? data?.submenu?.description
                      : data?.description}
                  </div>
                  <hr
                    className="h-5  w-28 absolute bottom-0"
                    style={
                      data?.menu && data?.menu?.color
                        ? { backgroundColor: data?.menu?.color }
                        : { backgroundColor: "#000" }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container py-5">
            {!data?.submenu &&
              data?.imagesMenu &&
              data?.imagesMenu?.length > 0 && (
                <div className="border p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {data?.imagesMenu?.map((img, index) => (
                      <NavLink
                        to={`${intl.formatMessage({
                          id: "link.sub",
                        })}/${data?.menu?.slug?.en?.current}/${
                          data?.slug?.en?.current
                        }/${img?.slug?.en?.current}`}
                        key={index}
                        className="flex flex-col gap-3"
                      >
                        <img
                          className="w-full h-[6rem] object-cover"
                          src={img.banner}
                          alt={img?.title}
                        />
                        <span className="text-[0.8rem] cursor-pointer hover:opacity-70">
                          {img.title}
                        </span>
                      </NavLink>
                    ))}
                  </div>
                </div>
              )}

            <div className="py-5">
              <PortableText
                value={data?.submenu ? data?.submenu?.content : data?.content}
                components={components(false)}
                onMissingComponent={false}
              />
            </div>

            <div className="flex gap-3">
              <NewsHome />
            </div>
          </div>
        </div>
      )}
      {!data && <ErrorPage />}
    </Layout>
  );
};

export default SubMenuPage;
