import { UPDATE_NEWS_DEFAULT } from "../constants";

const initialState = null;

export const setNewsDefaultData = (data) => ({
    type: UPDATE_NEWS_DEFAULT,
    data,
});

export const newsDefault = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_NEWS_DEFAULT:
            return action.data;
        default:
            return state;
    }
};
