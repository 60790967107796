import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

const CallToAction = () => {
    return (
        <NavLink
            to={"/contact"}
            className="relative btn-box-shadow bg-light text-white font-[400] text-[0.85rem] rounded-[5rem] inline-flex px-4 py-3 hover:bg-white hover:border-light hover:text-light hover:border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
        >
            <FormattedMessage id="call.to.action" />
            <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
        </NavLink>
    );
};

export default CallToAction;
