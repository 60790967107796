import { PortableText } from "@portabletext/react";
import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { components } from "../api/sanity/portableText";
import { urlFor } from "../api/sanity/utils";
import {
  ErrorPage,
  Layout,
  RelatedBlog
} from "../components";
import { socials } from "../constants/pages";
import { useLanguage } from "../context";
import { getBlogBySlug, getDatePost3 } from "../helpers/utils";
import { useNews } from "../redux/hooks/hooks";

const BlogDetail = () => {
  const data = useNews();
  const params = useParams();
  const intl = useIntl();
  const { language } = useLanguage();
  const blog = getBlogBySlug(data?.blogs || [], params?.slug);

  const url = window.location.href;

  return (
    <Layout data={data}>
      {data?.blogs && blog ? (
        <>
          <div
            className="bg-gray-100 md:h-80 h-60 bg-no-repeat bg-cover bg-center w-full"
            style={{
              backgroundImage: `url(${urlFor(blog?.image).fit("max").url()})`,
            }}
          >
            <div className="container h-full">
              <div
                className="flex items-center h-full font-[700] md:text-[2rem] text-[1.5rem] text-white"
                style={{ textShadow: "1px 1px 1px black" }}
              >
                {blog?.title}
              </div>
            </div>
          </div>
          <div className="container pt-8 pb-12">
            <div className="pb-8 flex flex-col md:flex-row relative">
              <div className="w-full md:w-[70%] md:pr-10">
                <div className="py-1 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <NavLink
                      to={`${intl.formatMessage({
                        id: "link.blog.categories",
                      })}/${blog?.category?.slug["en"]?.current}`}
                      className="text-light"
                    >
                      {blog?.category?.title}
                    </NavLink>
                    <span className="text-light-666">|</span>
                    <span className="text-light-666">
                      {blog && getDatePost3(blog?.date, language)}
                    </span>
                  </div>
                </div>
                <div className="py-2 flex gap-2">
                  {socials?.map((social, index) => (
                    <social.ShareButton
                      key={index}
                      url={url}
                      title={blog?.title}
                      className="share-button"
                    >
                      <social.ShareButtonIcon size={30} round />
                    </social.ShareButton>
                  ))}
                </div>
                <div className="py-2 text-justify">
                  {blog?.description && (
                    <PortableText
                      value={blog.description}
                      components={components(false)}
                      onMissingComponent={false}
                    />
                  )}
                </div>
                <div className="py-5 flex justify-start">
                  <NavLink
                    to={`${intl.formatMessage({
                      id: "link.news",
                    })}`}
                    className="text-light  p-3 flex hover:border hover:border-light rounded-md gap-2 transition ease-in-out delay-150 duration-300 hover:font-[900]"
                  >
                    <LuMoveLeft className="w-[1.5rem] h-[1.5rem]" />
                    <FormattedMessage id="back.to.blog" />
                  </NavLink>
                </div>
              </div>
              <div className="w-full md:w-[30%] md:ml-3 pt-2">
                <RelatedBlog />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="h-full">
          <ErrorPage />
        </div>
      )}
    </Layout>
  );
};

export default BlogDetail;
