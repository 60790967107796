import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { PortableText } from "@portabletext/react";
import { components } from "../../api/sanity/portableText";

const AccordionList = ({ data }) => {
  return (
    <>
      {data && data?.length > 0 && (
        <Accordion type="multiple" collapsible className="w-full">
          {data.map((item, i) => (
            <AccordionItem value={`item-${i}`}>
              <AccordionTrigger>{item?.title}</AccordionTrigger>
              <AccordionContent>
                {item?.content && (
                  <PortableText
                    value={item?.content}
                    components={components(false)}
                    onMissingComponent={false}
                  />
                )}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </>
  );
};
export default AccordionList;
