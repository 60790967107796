import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";

export const pages = [
    //   { name: "Home", href: "link.home", id: "home" },
    // {
    //     name: "Services",
    //     href: "link.services",
    //     id: "services",
    // },
    // {
    //     name: "Products",
    //     href: "link.products",
    //     id: "products",
    // },
    // {
    //     name: "Contact",
    //     href: "link.contact",
    //     id: "contact",
    // },
];

export const megaMenu = [
    {
        name: "Services",
        href: "link.servicess",
        id: "services",
    },
    {
        name: "Products",
        href: "link.productss",
        id: "products",
    },
    {
        name: "Contact",
        href: "link.contacts",
        id: "contact",
    },
];

export const headerLink = [
    { name: "News", href: "link.news", id: "news", divider: true },
    { name: "Contact", href: "link.contact", id: "contact",divider: true },
    // { name: "Where are you", href: "link.where.are.you", id: "where.are.you",divider: false },
];

export const socials = [
    { ShareButton: FacebookShareButton, ShareButtonIcon: FacebookIcon },
    { ShareButton: LinkedinShareButton, ShareButtonIcon: LinkedinIcon },
    { ShareButton: TwitterShareButton, ShareButtonIcon: XIcon },
    { ShareButton: TelegramShareButton, ShareButtonIcon: TelegramIcon },
    { ShareButton: WhatsappShareButton, ShareButtonIcon: WhatsappIcon },
];
