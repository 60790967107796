import React from "react";
import { urlFor } from "../../api/sanity/utils";
import { useLanguage } from "../../context";
import { getDatePost2 } from "../../helpers/utils";
import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";
import { setLocalStorage, slugBlog, slugCategory } from "../../helpers/localStorage";
const BlogH = ({ data }) => {
  const { language } = useLanguage();
  const intl = useIntl();

  const onPress = () => {
    setLocalStorage(slugCategory, data?.category?.slug?.en?.current);
    setLocalStorage(slugBlog, data?.slug?.en?.current);
  };
  
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-[40%]">
        {/* <img
          src={urlFor(data.image).fit("max").url()}
          className="h-[120px] md:h-[90px] w-full object-cover"
          alt=""
        /> */}
        <NavLink
          to={`${intl.formatMessage({
            id: "link.blog",
          })}/${data?.slug?.en?.current}`}
          className="h-[120px] md:h-[100px] w-full flex bg-center bg-cover"
          style={{
            backgroundImage: `url(${urlFor(data.image).fit("max").url()})`,
          }}
          onClick={() => onPress()}
        ></NavLink>
      </div>
      <div className="w-full md:w-[60%]">
        <div className="md:px-5">
          <div className="">
            <NavLink
              to={`${intl.formatMessage({
                id: "link.blog.categories",
              })}/${data.category?.slug?.en?.current}`}
              className="text-light hover:text-light/70"
            >
              {data.category?.title}
            </NavLink>
          </div>
          <div className="pt-2">
            <NavLink
              to={`${intl.formatMessage({
                id: "link.blog",
              })}/${data?.slug?.en?.current}`}
              className="font-[600] text-[0.85rem] hover:text-black/70"
              onClick={() => onPress()}
            >
              {data.title}
            </NavLink>
          </div>
          {/* <p className="pt-2">
            {`${data?.smallDescription}`.slice(0, 120)}
            {`...`}
          </p> */}
          <div className="flex items-center justify-end text-italic">
            <span className="text-light-666 text-[0.85rem]">
              {getDatePost2(data?._updatedAt, language)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogH;
