import React from "react";
import {
  CallToAction,
  FirstBlockHome,
  Layout,
  NewsHome,
  Newsletter,
  PartenersHome,
  SecondBlockHome,
  Slider,
} from "../components";
import { useHome } from "../redux/hooks/hooks";
import { Helmet } from "react-helmet-async";

export default function Home() {
  const data = useHome();

  console.log('meta --- ',data?.meta)
  return (
    <Layout data={data}>
      {data && (
        <>
          {data?.meta && (
            <Helmet>
              <meta name="description" content={data?.meta} />
            </Helmet>
          )}
          <Slider />
          <FirstBlockHome />
          <SecondBlockHome />
          <div className="container flex flex-col md:flex-row gap-4 pt-2">
            <NewsHome />
            <div className="w-full md:w-1/3  py-8">
              <div className="flex justify-center items-center pb-5">
                <CallToAction />
              </div>
              <Newsletter />
            </div>
          </div>
          <PartenersHome />
        </>
      )}
    </Layout>
  );
}
