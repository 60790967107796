import React from "react";
import {
  CarrouselBlog,
  TagBlog,
  PopularBlogs,
  RecentsBlogs,
  TrendingBlogs,
  Layout,
} from "../components";
import { useNews } from "../redux/hooks/hooks";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const data = useNews();

  return (
    <Layout data={data}>
      {data && (
        <>
        {data?.meta && (
            <Helmet>
              <meta name="description" content={data?.meta} />
            </Helmet>
          )}
          <div className="container py-4">
            <span className="font-[700] text-[1.5rem]">
              <FormattedMessage id="news" />
            </span>
          </div>
          <CarrouselBlog />
          <div className="container pb-10">
            <RecentsBlogs />
            <section className="flex flex-col md:flex-row pt-8">
              <div className="w-full md:w-2/3">
                <PopularBlogs />
              </div>
              <div className="w-full md:w-1/3 pt-4 md:pl-8">
                <TrendingBlogs />
                {/* <TagBlog /> */}
              </div>
            </section>
            <section>
              <TagBlog />
            </section>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Blog;
