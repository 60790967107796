import React from "react";
import { useHome } from "../../redux/hooks/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { urlFor } from "../../api/sanity/utils";
import { LuMoveRight } from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const SecondBlockHome = () => {
  const data = useHome();
  const intl = useIntl();

  return (
    <div className="container">
      {/* 
       data-aos="fade-up-right"
      data-aos-duration="500"
      data-aos-easing="ease-in"
      */}
      {/* <span className="font-[900] text-[2rem]">
        <FormattedMessage id="services.products" />
      </span> */}
      <div className="flex flex-col md:flex-row sm:gap-x-6 md:gap-x-11 gap-y-4 py-4">
        {data?.secondBlock?.map((info, index) => (
          <div key={index} className="w-full md:w-1/2">
            {info?.isProduct ? (
              <img
                className="w-full h-48"
                src={urlFor(info.image).fit("max").url()}
                alt="Lewoo"
              />
            ) : (
              <div className="flex">
                <Swiper
                  watchSlidesProgress={true}
                  slidesPerView={3}
                  className="w-full h-48 leading-4 text-center swiper-second-block"
                >
                  {info.images?.map((img, i) => (
                    <SwiperSlide key={i}>
                      <img
                        className="w-full h-full"
                        src={urlFor(img).fit("max").url()}
                        alt="Lewoo"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            <div className="flex flex-col pt-5 px-3">
              <NavLink
                to={
                  info?.isProduct
                    ? `${intl.formatMessage({
                      id: "link.products",
                    })}`
                    : `${intl.formatMessage({
                      id: "link.services",
                    })}`
                }
                className="font-[600] text-[1rem] hover:opacity-70"
              >
                {info.title}
              </NavLink>
              <p className="text-wrap text-[0.85rem] mt-2">
                {info.description}
              </p>
              {/* <div className="py-4 flex justify-end">
                <NavLink
                  to={
                    info?.isProduct
                      ? `${intl.formatMessage({
                        id: "link.products",
                      })}`
                      : `${intl.formatMessage({
                        id: "link.services",
                      })}`
                  }
                  className="text-primary px-2 border border-primary rounded-sm transition ease-in duration-300 hover:bg-primary hover:text-white"
                >
                  <LuMoveRight className="w-[1rem] h-[1.5rem]" />
                </NavLink>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecondBlockHome;
