import { UPDATE_NEWS } from "../constants";

const initialState = null;

export const setNewsData = (data) => ({
    type: UPDATE_NEWS,
    data,
});

export const news = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_NEWS:
            return action.data;
        default:
            return state;
    }
};
