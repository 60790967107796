import { PortableText } from "@portabletext/react";
import React from "react";
import { components } from "../api/sanity/portableText";
import { CallToAction, ContactInfo, Layout, Newsletter } from "../components";
import { useAbout } from "../redux/hooks/hooks";

const About = () => {
  const data = useAbout();
  return (
    <Layout data={data}>
      {data && (
        <>
          <div
            className="bg-gray-100 md:h-80 h-60 bg-no-repeat bg-cover bg-center w-full"
            style={{ backgroundImage: `url(${data?.hero?.image})` }}
          >
            <div className="container pt-24">
              <div
                className="font-[700] text-[2rem] text-white"
                style={{ textShadow: "1px 1px 1px black" }}
              >
                {/* <FormattedMessage id="about" /> */}
                {data?.hero?.title}
              </div>
              {data?.hero?.description && (
                <div
                  className="text-[1rem] text-white"
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  {/* <FormattedMessage id="about" /> */}
                  {data?.hero?.description}
                </div>
              )}
            </div>
          </div>
          <div className="container pb-12">
            <div className="flex flex-col md:flex-row w-full py-8 md:gap-5">
              <div className="w-full md:w-2/3">
                <div className="grid grid-cols-2 md:grid-cols-4">
                  {data?.statsBlock?.map((stat, index) => (
                    <div key={index} className="p-4">
                      <hr className="w-10 h-1 bg-light" />
                      <div className="flex gap-2 font-[400] text-light pt-2">
                        <span>{stat.value}</span>
                        <span>{stat.title}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="py-2 text-justify">
                  <PortableText
                    value={data?.description}
                    components={components(false)}
                    onMissingComponent={false}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3">
                <ContactInfo />
                <div className="flex justify-center pt-8">
                  <CallToAction />
                </div>
                <div className="pt-8">
                  <Newsletter />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default About;
