import getYouTubeID from "get-youtube-id";
import React from "react";
import { FormattedMessage } from "react-intl";
import YouTube from "react-youtube";
import {
  CallToAction,
  ContactInfo,
  ContentProduct,
  Layout,
  Newsletter,
} from "../components";
import { useProduct } from "../redux/hooks/hooks";
//import { productImage } from "../assets";

const Product = () => {
  const data = useProduct();
  const onReady = (event) => {
    event.target.pauseVideo();
  };
  return (
    <Layout data={data}>
      <div
        className="md:h-80 bg-gray-100 h-60 flex  bg-no-repeat bg-cover bg-center w-full"
        style={{ backgroundImage: `url(${data?.hero?.image})` }}
      >
        <div className="container pt-24">
          <div
            className="font-[700] text-[2rem] text-white"
            style={{ textShadow: "1px 1px 1px black" }}
          >
            {/* <FormattedMessage id="our.products" /> */}
            {data?.hero?.title}
          </div>
          <div
            className="text-[1rem] text-white"
            style={{ textShadow: "1px 1px 1px black" }}
          >
            {/* <FormattedMessage id="our.products" /> */}
            {data?.hero?.description}
          </div>
        </div>
      </div>
      <div className="container pt-2 pb-24">
        {/* <span className="font-[700] text-[2rem]">
          <FormattedMessage id="our.products" />
        </span> */}
        <div className="flex flex-col md:flex-row w-full md:gap-5">
          <div className="w-full md:w-2/3 pt-8">
            <ContentProduct />
            <div className="py-8">
              <span className="font-[700] text-[1rem]">
                <FormattedMessage id="videos" />
              </span>
              <div className="grid grid-cols-1 md:grid-cols-2 pt-4 gap-4">
                {data?.videos?.map((video, index) => (
                  <div key={index} className="w-full">
                    <YouTube
                      videoId={`${getYouTubeID(video)}`}
                      onReady={onReady}
                      iframeClassName="w-full h-[12rem] md:h-[10rem]"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <ContactInfo />
            <div className="flex justify-center pt-8">
              <CallToAction />
            </div>
            <div className="pt-8 md:px-2">
              <Newsletter />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Product;
